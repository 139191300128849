@charset "UTF-8";
.project-details-container.center-map-ui {
  position: relative;
  width: 100%;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui {
    bottom: 38px;
    flex-direction: column;
    right: 5.6%;
  }
  .map-container .project-details-container.center-map-ui {
    left: unset;
    width: 300px;
  }
}
.project-details-container.center-map-ui .details-wrapper {
  width: 100%;
  overflow: scroll hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .details-wrapper {
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.4);
    max-height: 100%;
    overflow: scroll;
    scrollbar-width: unset;
  }
}
.project-details-container.center-map-ui .details-list {
  display: flex;
  flex-direction: row;
  margin: 12px 0 0 0;
  padding: 0 85px 0 25px;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .details-list {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
.project-details-container.center-map-ui .project {
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 0;
  display: flex;
  flex-shrink: 0;
  line-height: 1;
  margin-bottom: 0;
  margin-right: 10px;
  padding: 0;
  white-space: normal;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project {
    display: block;
    margin: 1px;
  }
  .project-details-container.center-map-ui .project.active .project-expand::after {
    content: "–";
    top: 15px;
  }
  .project-details-container.center-map-ui .project.active .project-details {
    display: block;
  }
}
.project-details-container.center-map-ui .project.hover, .project-details-container.center-map-ui .project:hover, .project-details-container.center-map-ui .project.active {
  background: #f8f8f8;
}
.project-details-container.center-map-ui .project-expand {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  max-width: 245px;
  padding: 20px 30px 20px 20px;
  position: relative;
  text-align: left;
  width: 100%;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project-expand {
    max-width: none;
  }
}
.project-details-container.center-map-ui .project-expand::after {
  content: "+";
  display: block;
  font-size: 21px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project-expand::after {
    top: 22px;
  }
}
.project-details-container.center-map-ui .project-name {
  flex: 1;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.1em;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project-name {
    font-size: 18px;
    margin-left: 12px;
  }
}
.project-details-container.center-map-ui .project-icon {
  display: none;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project-icon {
    display: block;
    width: 20px;
  }
}
.project-details-container.center-map-ui .project-details {
  padding: 0 30px 20px 20px;
  display: none;
}
.project-details-container.center-map-ui .project-details-scrollers--mobile {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 26%, rgb(255, 255, 255) 100%);
  display: flex;
  gap: 5px;
  padding: 25px 25px;
  position: absolute;
  right: 0;
  top: 28px;
}
.project-details-container.center-map-ui .project-details-scrollers--desktop {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}
.project-details-container.center-map-ui .project-details-scroller {
  appearance: none;
  background: black;
  border: none;
  cursor: pointer;
  height: 22px;
  width: 22px;
}
@media (min-width: 62rem) {
  .project-details-container.center-map-ui .project-details-scroller {
    padding-bottom: 4px;
  }
}
.project-details-container.center-map-ui .project-details-scroller path {
  fill: white;
}
