.SocialLinks {
  display: flex;
  flex-direction: row;
}
.SocialLinks .SocialLink {
  margin-left: 12px;
}
.SocialLinks .SocialLink:first-child {
  margin-left: 0;
}
