@font-face {
  font-family: "thirdway-icons";
  src: url("../fonts/thirdway-icons/thirdway-icons.eot?qtdr33");
  src: url("../fonts/thirdway-icons/thirdway-icons.eot?qtdr33#iefix") format("embedded-opentype"), url("../fonts/thirdway-icons/thirdway-icons.ttf?qtdr33") format("truetype"), url("../fonts/thirdway-icons/thirdway-icons.woff?qtdr33") format("woff"), url("../fonts/thirdway-icons/thirdway-icons.svg?qtdr33#thirdway-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon {
  font-family: "thirdway-icons" !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-health:before {
  content: "\e910";
}

.icon-bookmark:before {
  content: "\e90e";
}

.icon-close:before {
  content: "\e90c";
  color: #494949;
}

.icon-academic-cap:before {
  content: "\e901";
}

.icon-bolt:before {
  content: "\e902";
}

.icon-chat-bubble:before {
  content: "\e903";
}

.icon-leaf:before {
  content: "\e904";
}

.icon-nut:before {
  content: "\e905";
}

.icon-person:before {
  content: "\e906";
}
