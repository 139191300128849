@media only screen {
  .wrapper {
    box-sizing: border-box;
    max-width: 90rem;
    margin: 0 auto;
  }
}

@media only screen {
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    max-width: 1260px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media only screen and (min-width: 62rem) {
  .container-fluid {
    box-sizing: content-box;
    padding-left: 5.625rem;
    padding-right: 5.625rem;
  }
}
@media only print {
  .container-fluid {
    float: none;
    padding-bottom: 0 !important;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: auto;
}

@media print {
  .col-xs-print {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto !important;
  }
}
.col-xs-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

@media print {
  .col-xs-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333% !important;
    max-width: 8.3333333333% !important;
  }
}
.col-xs-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

@media print {
  .col-xs-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }
}
.col-xs-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 25%;
  max-width: 25%;
}

@media print {
  .col-xs-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
}
.col-xs-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

@media print {
  .col-xs-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
}
.col-xs-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

@media print {
  .col-xs-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667% !important;
    max-width: 41.6666666667% !important;
  }
}
.col-xs-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 50%;
  max-width: 50%;
}

@media print {
  .col-xs-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}
.col-xs-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

@media print {
  .col-xs-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333% !important;
    max-width: 58.3333333333% !important;
  }
}
.col-xs-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

@media print {
  .col-xs-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
}
.col-xs-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 75%;
  max-width: 75%;
}

@media print {
  .col-xs-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
}
.col-xs-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

@media print {
  .col-xs-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333% !important;
    max-width: 83.3333333333% !important;
  }
}
.col-xs-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

@media print {
  .col-xs-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667% !important;
    max-width: 91.6666666667% !important;
  }
}
.col-xs-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  flex-basis: 100%;
  max-width: 100%;
}

@media print {
  .col-xs-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}
.col-xs-offset-0 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 0;
}

@media print {
  .col-xs-offset-print-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
}
.col-xs-offset-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 8.3333333333%;
}

@media print {
  .col-xs-offset-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
}
.col-xs-offset-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 16.6666666667%;
}

@media print {
  .col-xs-offset-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
}
.col-xs-offset-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 25%;
}

@media print {
  .col-xs-offset-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
}
.col-xs-offset-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 33.3333333333%;
}

@media print {
  .col-xs-offset-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
}
.col-xs-offset-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 41.6666666667%;
}

@media print {
  .col-xs-offset-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
}
.col-xs-offset-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 50%;
}

@media print {
  .col-xs-offset-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
}
.col-xs-offset-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 58.3333333333%;
}

@media print {
  .col-xs-offset-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
}
.col-xs-offset-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 66.6666666667%;
}

@media print {
  .col-xs-offset-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
}
.col-xs-offset-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 75%;
}

@media print {
  .col-xs-offset-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
}
.col-xs-offset-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 83.3333333333%;
}

@media print {
  .col-xs-offset-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
}
.col-xs-offset-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 91.6666666667%;
}

@media print {
  .col-xs-offset-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
}
.col-xs-offset-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-left: 100%;
}

@media print {
  .col-xs-offset-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
}
.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

@media print {
  .col-xs-print {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100% !important;
  }
}
.start-xs {
  justify-content: flex-start;
  text-align: left;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: right;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media print, screen and (min-width: 36rem) {
  .container {
    width: 35rem;
  }
  .col-sm {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-sm-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-sm-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-sm {
    justify-content: flex-start;
    text-align: left;
  }
  .center-sm {
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    justify-content: flex-end;
    text-align: right;
  }
  .top-sm {
    align-items: flex-start;
  }
  .middle-sm {
    align-items: center;
  }
  .bottom-sm {
    align-items: flex-end;
  }
  .around-sm {
    justify-content: space-around;
  }
  .between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media print and (min-width: 36rem) {
  .container-print {
    width: 35rem !important;
  }
  .col-sm-print {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-sm-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333% !important;
    max-width: 8.3333333333% !important;
  }
  .col-sm-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }
  .col-sm-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .col-sm-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
  .col-sm-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667% !important;
    max-width: 41.6666666667% !important;
  }
  .col-sm-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .col-sm-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333% !important;
    max-width: 58.3333333333% !important;
  }
  .col-sm-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
  .col-sm-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .col-sm-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333% !important;
    max-width: 83.3333333333% !important;
  }
  .col-sm-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667% !important;
    max-width: 91.6666666667% !important;
  }
  .col-sm-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .col-sm-offset-print-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-sm-offset-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-sm-offset-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-sm-offset-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-sm-offset-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-sm-print {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100% !important;
  }
  .start-sm-print {
    justify-content: flex-start;
    text-align: left;
  }
  .center-sm-print {
    justify-content: center;
    text-align: center;
  }
  .end-sm-print {
    justify-content: flex-end;
    text-align: right;
  }
  .top-sm-print {
    align-items: flex-start;
  }
  .middle-sm-print {
    align-items: center;
  }
  .bottom-sm-print {
    align-items: flex-end;
  }
  .around-sm-print {
    justify-content: space-around;
  }
  .between-sm-print {
    justify-content: space-between;
  }
  .first-sm-print {
    order: -1;
  }
  .last-sm-print {
    order: 1;
  }
}
@media print, screen and (min-width: 48rem) {
  .container {
    width: 46rem;
  }
  .col-md {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-md-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-md-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-md-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-md-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-md-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-md {
    justify-content: flex-start;
    text-align: left;
  }
  .center-md {
    justify-content: center;
    text-align: center;
  }
  .end-md {
    justify-content: flex-end;
    text-align: right;
  }
  .top-md {
    align-items: flex-start;
  }
  .middle-md {
    align-items: center;
  }
  .bottom-md {
    align-items: flex-end;
  }
  .around-md {
    justify-content: space-around;
  }
  .between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media print and (min-width: 48rem) {
  .container-print {
    width: 46rem !important;
  }
  .col-md-print {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-md-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333% !important;
    max-width: 8.3333333333% !important;
  }
  .col-md-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }
  .col-md-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .col-md-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
  .col-md-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667% !important;
    max-width: 41.6666666667% !important;
  }
  .col-md-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .col-md-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333% !important;
    max-width: 58.3333333333% !important;
  }
  .col-md-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
  .col-md-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .col-md-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333% !important;
    max-width: 83.3333333333% !important;
  }
  .col-md-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667% !important;
    max-width: 91.6666666667% !important;
  }
  .col-md-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .col-md-offset-print-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-md-offset-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-md-offset-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-md-offset-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-md-offset-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-md-offset-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-md-offset-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-md-offset-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-md-offset-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-md-offset-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-md-offset-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-md-offset-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-md-offset-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-md-print {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100% !important;
  }
  .start-md-print {
    justify-content: flex-start;
    text-align: left;
  }
  .center-md-print {
    justify-content: center;
    text-align: center;
  }
  .end-md-print {
    justify-content: flex-end;
    text-align: right;
  }
  .top-md-print {
    align-items: flex-start;
  }
  .middle-md-print {
    align-items: center;
  }
  .bottom-md-print {
    align-items: flex-end;
  }
  .around-md-print {
    justify-content: space-around;
  }
  .between-md-print {
    justify-content: space-between;
  }
  .first-md-print {
    order: -1;
  }
  .last-md-print {
    order: 1;
  }
}
@media print, screen and (min-width: 62rem) {
  .container {
    width: 59rem;
  }
  .col-lg {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-lg-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-lg-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-lg {
    justify-content: flex-start;
    text-align: left;
  }
  .center-lg {
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    justify-content: flex-end;
    text-align: right;
  }
  .top-lg {
    align-items: flex-start;
  }
  .middle-lg {
    align-items: center;
  }
  .bottom-lg {
    align-items: flex-end;
  }
  .around-lg {
    justify-content: space-around;
  }
  .between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
@media print and (min-width: 62rem) {
  .container-print {
    width: 59rem !important;
  }
  .col-lg-print {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-lg-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333% !important;
    max-width: 8.3333333333% !important;
  }
  .col-lg-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }
  .col-lg-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .col-lg-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
  .col-lg-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667% !important;
    max-width: 41.6666666667% !important;
  }
  .col-lg-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .col-lg-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333% !important;
    max-width: 58.3333333333% !important;
  }
  .col-lg-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
  .col-lg-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .col-lg-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333% !important;
    max-width: 83.3333333333% !important;
  }
  .col-lg-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667% !important;
    max-width: 91.6666666667% !important;
  }
  .col-lg-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .col-lg-offset-print-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-lg-offset-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-lg-offset-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-lg-offset-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-lg-offset-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-lg-print {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100% !important;
  }
  .start-lg-print {
    justify-content: flex-start;
    text-align: left;
  }
  .center-lg-print {
    justify-content: center;
    text-align: center;
  }
  .end-lg-print {
    justify-content: flex-end;
    text-align: right;
  }
  .top-lg-print {
    align-items: flex-start;
  }
  .middle-lg-print {
    align-items: center;
  }
  .bottom-lg-print {
    align-items: flex-end;
  }
  .around-lg-print {
    justify-content: space-around;
  }
  .between-lg-print {
    justify-content: space-between;
  }
  .first-lg-print {
    order: -1;
  }
  .last-lg-print {
    order: 1;
  }
}
@media print, screen and (min-width: 75rem) {
  .container {
    width: 70rem;
  }
  .col-xl {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-xl-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xl-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-xl-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-xl-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-xl-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-xl-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-xl-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-xl-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-xl-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-xl-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-xl {
    justify-content: flex-start;
    text-align: left;
  }
  .center-xl {
    justify-content: center;
    text-align: center;
  }
  .end-xl {
    justify-content: flex-end;
    text-align: right;
  }
  .top-xl {
    align-items: flex-start;
  }
  .middle-xl {
    align-items: center;
  }
  .bottom-xl {
    align-items: flex-end;
  }
  .around-xl {
    justify-content: space-around;
  }
  .between-xl {
    justify-content: space-between;
  }
  .first-xl {
    order: -1;
  }
  .last-xl {
    order: 1;
  }
}
@media print and (min-width: 75rem) {
  .container-print {
    width: 70rem !important;
  }
  .col-xl-print {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: auto;
  }
  .col-xl-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 8.3333333333% !important;
    max-width: 8.3333333333% !important;
  }
  .col-xl-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }
  .col-xl-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 25% !important;
    max-width: 25% !important;
  }
  .col-xl-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }
  .col-xl-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 41.6666666667% !important;
    max-width: 41.6666666667% !important;
  }
  .col-xl-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .col-xl-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 58.3333333333% !important;
    max-width: 58.3333333333% !important;
  }
  .col-xl-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 66.6666666667% !important;
    max-width: 66.6666666667% !important;
  }
  .col-xl-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 75% !important;
    max-width: 75% !important;
  }
  .col-xl-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 83.3333333333% !important;
    max-width: 83.3333333333% !important;
  }
  .col-xl-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 91.6666666667% !important;
    max-width: 91.6666666667% !important;
  }
  .col-xl-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .col-xl-offset-print-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 0;
  }
  .col-xl-offset-print-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 8.3333333333%;
  }
  .col-xl-offset-print-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 16.6666666667%;
  }
  .col-xl-offset-print-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 25%;
  }
  .col-xl-offset-print-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 33.3333333333%;
  }
  .col-xl-offset-print-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 41.6666666667%;
  }
  .col-xl-offset-print-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 50%;
  }
  .col-xl-offset-print-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 58.3333333333%;
  }
  .col-xl-offset-print-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 66.6666666667%;
  }
  .col-xl-offset-print-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 75%;
  }
  .col-xl-offset-print-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 83.3333333333%;
  }
  .col-xl-offset-print-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 91.6666666667%;
  }
  .col-xl-offset-print-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-left: 100%;
  }
  .col-xl-print {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100% !important;
  }
  .start-xl-print {
    justify-content: flex-start;
    text-align: left;
  }
  .center-xl-print {
    justify-content: center;
    text-align: center;
  }
  .end-xl-print {
    justify-content: flex-end;
    text-align: right;
  }
  .top-xl-print {
    align-items: flex-start;
  }
  .middle-xl-print {
    align-items: center;
  }
  .bottom-xl-print {
    align-items: flex-end;
  }
  .around-xl-print {
    justify-content: space-around;
  }
  .between-xl-print {
    justify-content: space-between;
  }
  .first-xl-print {
    order: -1;
  }
  .last-xl-print {
    order: 1;
  }
}
