.MapPageCategoryFilter #category-sticky-container {
  padding-bottom: 17px;
  padding-top: 13px;
  transition: top 0.25s ease, opacity 0.2s ease;
  width: 100%;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container {
    padding-bottom: 24px;
    padding-top: 26px;
  }
}
.MapPageCategoryFilter #category-sticky-container.is-sticky {
  background-color: #ffffff;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  z-index: 10;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky {
    display: flex;
    flex-direction: row;
  }
  .MapPageCategoryFilter #category-sticky-container.is-sticky .FilterButton:first-of-type {
    margin-left: 30px;
  }
}
.MapPageCategoryFilter #category-sticky-container.is-sticky.header-visible {
  top: 48px;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky.header-visible {
    top: 78px;
  }
}
.MapPageCategoryFilter #category-sticky-container.is-sticky .heading {
  align-items: center;
  display: flex;
  max-width: none;
  padding-right: 15px;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .heading {
    border-right: 1px solid #E5E5E5;
    margin-bottom: 0;
  }
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-container {
    padding-left: 15px;
  }
}
@media (min-width: 48rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-desktop-overlay {
    display: flex;
  }
}
@media (min-width: 48rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-wrapper {
    max-width: 100%;
  }
}
.MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-list {
  display: flex;
  align-items: center;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-list {
    max-width: 100%;
  }
}
.MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-list .FilterButton > label {
  margin-bottom: 0;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container.is-sticky .category-filter-scrollers {
    display: flex;
    right: 115px;
  }
}
.MapPageCategoryFilter #category-sticky-container .heading {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  padding: 0 25px;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container .heading {
    box-sizing: content-box;
    margin: 0 auto 1rem;
    max-width: 1260px;
    padding-left: calc(5.625rem + 25px);
    padding-right: calc(5.625rem + 25px);
    -webkit-box-sizing: content-box;
  }
}
.MapPageCategoryFilter #category-sticky-container .category-filter-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container .category-filter-container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1260px;
    padding-left: calc(5.625rem + 25px);
    padding-right: calc(5.625rem + 25px);
    -webkit-box-sizing: content-box;
    width: inherit;
  }
}
.MapPageCategoryFilter #category-sticky-container .category-filter-desktop-overlay {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 26%, rgb(255, 255, 255) 100%);
  display: none;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 47px;
}
.MapPageCategoryFilter #category-sticky-container .category-filter-wrapper {
  width: 100%;
}
.MapPageCategoryFilter #category-sticky-container .category-filter-list {
  display: flex;
  flex-direction: row;
  padding: 0 85px 0 25px;
  overflow: scroll hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container .category-filter-list {
    display: block;
    max-width: 711px;
    padding: 0 110px 0 0;
  }
}
.MapPageCategoryFilter #category-sticky-container .category-filter-scrollers {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 26%, rgb(255, 255, 255) 100%);
  display: flex;
  gap: 5px;
  padding: 4.5px 25px;
  position: absolute;
  right: 0;
}
@media (min-width: 62rem) {
  .MapPageCategoryFilter #category-sticky-container .category-filter-scrollers {
    display: none;
    padding: 4.5px 0 4.5px 25px;
    right: 90px;
  }
}
.MapPageCategoryFilter #category-sticky-container .category-filter-scrollers .category-filter-scroller {
  appearance: none;
  background: black;
  border: none;
  cursor: pointer;
  height: 22px;
  width: 22px;
}
.MapPageCategoryFilter #category-sticky-container .category-filter-scrollers .category-filter-scroller path {
  fill: white;
}

.FilterButton {
  appearance: none;
  background: transparent;
  border: none;
  background: #F7F1F1;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 2px 9px 5px;
  white-space: nowrap;
}
.FilterButton .MapIcons {
  position: relative;
  top: 3px;
}
.FilterButton .MapIcons .injected-svg path {
  fill: #2B2B2B;
}
.FilterButton .MapIcons {
  display: inline-block;
}
.FilterButton.active {
  background-color: var(--background-color);
}
.FilterButton.active .button-text {
  color: #ffffff;
}
.FilterButton.active .MapIcons .injected-svg path {
  fill: #ffffff;
}
