.IssuePageFeaturedItem .image {
  position: relative;
}
.IssuePageFeaturedItem .image a {
  display: block;
  width: 100%;
}
.IssuePageFeaturedItem .image img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.IssuePageFeaturedItem .image .publication-logo {
  bottom: 0;
  height: auto;
  max-width: 100px;
  position: absolute;
  right: 0;
  width: 32%;
}
