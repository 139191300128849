.Count {
  bottom: 2px;
  font-family: "Merriweather", serif;
  font-size: 11px;
  font-weight: bold;
  margin-left: 9px;
  position: relative;
}
@media only screen {
  .Count {
    opacity: 0.45;
  }
}
