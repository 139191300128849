.AppFooter {
  background-color: #2b2b2b;
  display: flex;
  flex-direction: column;
}
@media only print {
  .AppFooter {
    display: none;
  }
}
.AppFooter .AppLogo {
  padding: 17px 0 0 14px;
}
@media (min-width: 62rem) {
  .AppFooter .AppLogo {
    margin-bottom: 24px;
    margin-right: 24px;
    padding: 0;
  }
}
.AppFooter .SecondaryNav {
  padding: 17px 14px 0 14px;
}
@media (min-width: 62rem) {
  .AppFooter .SecondaryNav {
    margin-bottom: 24px;
    padding: 0;
  }
}
.AppFooter .IssuesNav {
  display: none;
}
@media (min-width: 62rem) {
  .AppFooter .IssuesNav {
    display: block;
    padding: 0 48px 0 48px;
  }
}
.AppFooter.menu-is-visible .IssuesNav {
  display: block;
}
.AppFooter .footer-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}
.AppFooter .footer-row:first-child {
  margin-bottom: 18px;
}
@media (min-width: 62rem) {
  .AppFooter .footer-row:first-child {
    flex-wrap: wrap;
    padding: 48px 48px 0;
    margin-bottom: 22px;
  }
}
.AppFooter .footer-row:nth-child(2) {
  margin-bottom: 60px;
}
.AppFooter .footer-row:last-child {
  margin-bottom: 40px;
}
@media (min-width: 62rem) {
  .AppFooter .footer-row > .IssuesNav > ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: -60px;
  }
  .AppFooter .footer-row > .IssuesNav > ul > li {
    margin-bottom: 60px;
  }
}
@media (min-width: 75rem) {
  .AppFooter .footer-row > .IssuesNav > ul {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
  .AppFooter .footer-row > .IssuesNav > ul > li {
    margin-bottom: 0;
  }
}
.AppFooter .controls {
  align-items: center;
  display: inline-flex;
  padding: 18px 20px 0 0;
}
.AppFooter .controls .icon {
  color: #ffffff;
  cursor: pointer;
}
.AppFooter .AppLogo {
  display: flex;
}
.AppFooter .PrimaryNav {
  display: flex;
}
.AppFooter .SocialLinks {
  order: 1;
}
@media (min-width: 62rem) {
  .AppFooter .SocialLinks {
    order: 2;
  }
}
.AppFooter .bottom-links {
  flex-direction: column;
  order: 2;
}
@media (min-width: 62rem) {
  .AppFooter .bottom-links {
    flex-direction: row;
    order: 1;
  }
}
