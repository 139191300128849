@media only screen {
  .OpportunitiesPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .OpportunitiesPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .OpportunitiesPage {
    padding-top: 0 !important;
  }
}

.OpportunitiesPage > .primary {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.91) 0%, rgb(255, 255, 255) 2%, rgb(255, 255, 255) 100%);
  margin-top: 150px;
  padding-top: 25px;
  position: relative;
  z-index: 2;
}
@media (min-width: 62rem) {
  .OpportunitiesPage > .primary {
    margin-top: 468px;
    padding-top: 55px;
  }
}
.OpportunitiesPage .apply-and-share {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.OpportunitiesPage .apply-and-share .SocialSharing {
  border: none;
  display: inline-flex;
  margin-left: 0;
  padding: 0;
}
