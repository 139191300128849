.IssueTagList {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.IssueTagList li {
  background: none;
  margin-bottom: 0.5rem;
  padding: 0;
}
.IssueTagList.inline li {
  display: inline-block;
  margin-right: 0.5rem;
}
.IssueTagList.inline li:last-child {
  margin-bottom: 0.5rem;
}
