@media only screen {
  .PressReleasePage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .PressReleasePage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .PressReleasePage {
    padding-top: 0 !important;
  }
}
