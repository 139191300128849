.series-group {
  border-bottom: 4px solid #000000;
  margin-bottom: 2.5rem;
}
.series-group .group-heading {
  margin-bottom: 0.5rem;
}
@media (min-width: 62rem) {
  .series-group .group-heading {
    margin-bottom: 1rem;
  }
}
.series-group .group-description {
  margin-bottom: 1.5rem;
}
@media (min-width: 62rem) {
  .series-group .group-description {
    margin-bottom: 2rem;
  }
}
.series-group h2 {
  font-size: 34px;
}
.series-group h3 {
  font-size: 24px;
}
@media (min-width: 62rem) {
  .series-group h3 {
    font-size: 28px;
  }
}
.series-group .group-items {
  margin-top: 1.5rem;
}
@media (min-width: 62rem) {
  .series-group .group-items {
    margin-top: 2rem;
  }
}
.series-group .series-button {
  margin: 2.5rem 0;
}
@media (min-width: 48rem) {
  .series-group .series-button {
    margin: 2.5rem 0 2.5rem calc(33.33% + 12px);
  }
}
