.ProductEndnotes {
  padding: 20px 25px;
  position: relative;
  word-break: break-word;
  word-wrap: break-word;
}
@media only screen {
  .ProductEndnotes {
    background: #f3f3f3;
  }
}
@media only print {
  .ProductEndnotes {
    break-before: page;
    padding-top: 0;
  }
}
@media only print {
  .ProductEndnotes.mt-5 {
    margin-top: 0 !important;
  }
}
@media only screen {
  .ProductEndnotes .Content {
    display: none;
  }
}
.ProductEndnotes .Content.active {
  display: block;
}
.ProductEndnotes > h4 {
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  line-height: 1.5;
  text-transform: uppercase;
}
@media only print {
  .ProductEndnotes > h4 {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}
.ProductEndnotes .icon-caret-right {
  font-size: 26px;
  transition-property: transform;
  transition-duration: 0.25s;
}
.ProductEndnotes .icon-caret-right.active {
  transform: rotate(90deg);
}
@media only print {
  .ProductEndnotes .icon-caret-right {
    display: none;
  }
}
.ProductEndnotes ol {
  list-style: none;
  margin: 0 0 15px 0;
}
.ProductEndnotes ol li {
  counter-increment: item;
  font-size: 14px;
  margin-left: 0;
  padding-left: 40px;
  position: relative;
}
.ProductEndnotes ol li:before {
  content: counter(item) ".";
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  left: 0;
}
@media only print {
  .ProductEndnotes ol li:before {
    font-size: 14px;
  }
}
