@charset "UTF-8";
@media only screen {
  .Content li a:not(.label), .Content a:not(.label) {
    color: #2b2b2b;
  }
}
@media only print {
  .Content li a:not(.label), .Content a:not(.label) {
    color: #000000;
  }
}

.Content {
  word-break: break-word;
}
.Content h1,
.Content h2,
.Content h3,
.Content h4,
.Content h5,
.Content h6 {
  margin-bottom: 15px;
}
.Content h1 a,
.Content h2 a,
.Content h3 a,
.Content h4 a,
.Content h5 a,
.Content h6 a {
  color: inherit;
}
.Content h1 a:hover,
.Content h2 a:hover,
.Content h3 a:hover,
.Content h4 a:hover,
.Content h5 a:hover,
.Content h6 a:hover {
  color: rgb(17.5, 17.5, 17.5);
  text-decoration: none;
}
.Content h1 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (min-width: 500px) {
  .Content h1 {
    font-size: 37px;
    line-height: 46px;
    margin-bottom: 30px;
  }
}
.Content h2 {
  font-size: 28px;
  line-height: 38px;
}
.Content h3 {
  font-size: 24px;
  line-height: 31px;
}
.Content img.tableau-fallback-image {
  display: none;
}
@media only print {
  .Content img.tableau-fallback-image {
    display: block;
  }
}
@media only print {
  .Content .tableau {
    display: none !important;
  }
}
@media only screen {
  .Content a:not(.label) {
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
    background-image: linear-gradient(to top, transparent, transparent 1px, #ffa11e 1px, #ffa11e 3px, transparent 3px);
    background-repeat: none;
    color: #2b2b2b;
    position: relative;
    text-decoration: none;
  }
}
.Content a:not(.label):focus, .Content a:not(.label):hover {
  color: #ffa11e;
}
.Content a.label {
  text-decoration: none;
  color: #3a6596;
  display: inline-block;
  font-family: "League Spartan", sans-serif;
  text-decoration: none;
}
.Content a.label:focus, .Content a.label:hover {
  color: #ffa11e;
}
.Content sup {
  font-size: smaller;
  padding: 0 0.25rem;
  vertical-align: super;
}
.Content ul {
  margin: 0 0 15px;
}
.Content ol {
  margin: 0 0 15px 13px;
}
@media only screen {
  .Content li a:not(.label) {
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
    background-image: linear-gradient(to top, transparent, transparent 1px, #ffa11e 1px, #ffa11e 3px, transparent 3px);
    background-repeat: none;
    color: #2b2b2b;
    position: relative;
    text-decoration: none;
  }
}
.Content li a:not(.label):focus, .Content li a:not(.label):hover {
  color: #ffa11e;
}
@media only screen and (min-width: 62rem) {
  .Content table, .Content .table-heading {
    margin-left: -343px;
    max-width: none;
    width: 960px;
  }
}
@media only print {
  .Content table, .Content .table-heading {
    overflow: visible !important;
    transform: translateX(-33.33333%);
    width: 149.9999925% !important;
  }
}
.Content table {
  margin-bottom: 1rem;
}
@media only screen {
  .Content table {
    overflow: scroll;
  }
}
@media only print {
  .Content table caption {
    caption-side: bottom;
    font-family: "Merriweather", serif;
    font-size: 13px;
    font-style: italic;
    line-height: 1.5;
    margin-top: 1rem;
    text-align: left;
  }
}
.Content table thead {
  background: #2b2b2b;
  color: #ffffff;
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  line-height: 17px;
  word-break: keep-all;
}
@media only print {
  .Content table thead {
    background: #000000;
  }
}
.Content table thead th {
  padding: 15px;
}
@media only screen {
  .Content table thead th {
    white-space: nowrap;
  }
}
@media only print {
  .Content table thead th {
    font-size: 12px;
    text-align: left;
  }
}
.Content table thead th p {
  margin: 0;
  text-align: left;
}
.Content table tbody {
  word-break: keep-all;
}
@media only screen {
  .Content table tbody tr:nth-child(2n+2) {
    background: #f3f3f3;
  }
}
@media only print {
  .Content table tbody tr {
    border-bottom: 0.2645833333mm solid #000000;
  }
}
.Content table tbody tr td {
  font-size: 13px;
  line-height: 17px;
  padding: 15px;
}
@media only print {
  .Content table tbody tr td {
    line-height: 1.23;
  }
}
.Content table tbody tr td p {
  margin: 0;
}
.Content .caption,
.Content .footnote,
.Content tfoot tr td {
  color: rgba(43, 43, 43, 0.65);
  font-size: 13px;
  line-height: 20px;
}
.Content .featured-image > img {
  height: auto;
  max-width: 100%;
}
.Content .ArticleInfo .type {
  color: #000000;
}
.Content .authors-short {
  display: block;
  font-weight: bold;
}
.Content ul {
  list-style-type: disc;
  margin-bottom: 15px;
  padding-left: 16px;
}
.Content img {
  display: block;
  height: auto;
  max-width: 100%;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: avoid;
}
.Content blockquote {
  background: linear-gradient(to right, #ededed, #ededed 4px, transparent 4px, transparent), linear-gradient(to left, #ededed, #ededed 4px, transparent 4px, transparent);
  border-bottom: 4px solid #ededed;
  border-top: 12px solid #ffa11e;
  font-size: 18px;
  line-height: 30px;
  margin: 2rem 0;
  padding: 20px 24px;
  position: relative;
}
.Content blockquote p {
  font-style: italic;
}
.Content blockquote:before, .Content blockquote:after {
  color: #ededed;
  font-size: 72px;
  font-weight: bold;
  line-height: 1;
  height: 72px;
  position: absolute;
  z-index: -1;
}
.Content blockquote:before {
  content: "“";
  left: 6px;
  top: 10px;
}
.Content blockquote:after {
  content: "”";
  bottom: -25px;
  right: 6px;
}
.Content blockquote p:last-child {
  margin-bottom: 0;
}
@media (min-width: 62rem) {
  .Content blockquote {
    padding: 45px 49px;
    margin-left: -343px;
    width: calc(100% + 343px);
  }
}
.Content .twitter-tweet-rendered {
  margin: 2rem auto !important;
}
.Content .pullquote {
  background-color: #ffffff;
  font-family: "League Spartan", sans-serif;
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 28px;
  margin-left: -1rem;
  width: calc(100% + 1rem);
}
.Content .pullquote em {
  font-style: normal;
}
@media only screen {
  .Content .pullquote {
    border-left: 32px solid #ffa11e;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 62rem) {
  .Content .pullquote {
    border-left: 64px solid #ffa11e;
    margin-left: calc(-66.66667% - 2rem);
    width: 166.66667%;
  }
}
@media only print {
  .Content .pullquote {
    page-break-inside: avoid;
    position: relative;
    margin-left: 0;
    width: 100%;
  }
}
.Content .pullquote .pullquote-share {
  margin-top: 1rem;
}
.Content .pullquote .pullquote-share .SocialMediaShareButton {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}
.Content .pullquote .pullquote-share .SocialMediaShareButton > span {
  color: #2ba9e0;
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  margin-left: 10px;
  margin-top: 5px;
  text-transform: uppercase;
}
.Content .pullquote .pullquote-share {
  margin-top: 1rem;
}
.Content .pullquote .pullquote-share .SocialMediaShareButton {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
}
.Content .pullquote .pullquote-share .SocialMediaShareButton > span {
  color: #2ba9e0;
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  margin-left: 10px;
  margin-top: 5px;
  text-transform: uppercase;
}
.Content .caption {
  margin-top: 1rem;
}
.Content .question {
  font-weight: 700;
}
.Content div[data-js-card-stack] {
  display: none;
}
.Content .entry-image {
  clear: both;
  margin-bottom: 15px;
  position: relative;
}
.Content .entry-image > div {
  position: relative;
}
.Content .entry-image .entry-image-caption {
  color: grey;
  display: block;
  font-family: "Merriweather", serif;
  font-style: italic;
  font-size: 13px;
  line-height: 1.2;
  padding-left: 10px;
  padding-top: 5px;
  text-align: left;
}
@media (min-width: 48rem) {
  .Content .entry-image:nth-child(2) .entry-image-caption {
    padding-left: 0;
    padding-right: 10px;
    text-align: right;
  }
}
.Content .entry-image .entry-image-share {
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 0.15s ease;
}
.Content .entry-image .entry-image-share .SocialLink:hover {
  opacity: 1;
}
.Content .entry-image:hover .entry-image-share {
  opacity: 1;
}
.Content .entry-image.entry-image-left {
  clear: left;
  float: left;
  overflow: hidden;
  margin: 0 15px 5px 0;
}
@media (min-width: 48rem) {
  .Content .entry-image.entry-image-left {
    max-width: 50%;
  }
}
@media (min-width: 62rem) {
  .Content .entry-image.entry-image-left {
    margin-left: -50px;
  }
}
@media only print {
  .Content .entry-image.entry-image-left {
    margin-left: -3.125rem !important;
    width: calc(50% + 3.125rem) !important;
  }
}
.Content .entry-image.entry-image-right {
  clear: right;
  float: right;
  overflow: hidden;
  margin: 0 0 5px 15px;
}
.Content .entry-image.entry-image-right > .entry-image-caption {
  text-align: right;
}
@media (min-width: 48rem) {
  .Content .entry-image.entry-image-right {
    max-width: 50%;
  }
}
@media (min-width: 62rem) {
  .Content .entry-image.entry-image-right {
    margin-right: -50px;
  }
}
@media only print {
  .Content .entry-image.entry-image-right {
    margin-right: -3.125rem !important;
    width: calc(50% + 3.125rem) !important;
  }
}
.Content .entry-image.entry-image-offset {
  display: block;
  margin: 0 auto;
}
@media (min-width: 62rem) {
  .Content .entry-image.entry-image-offset {
    margin: 0;
    margin-left: -343px;
  }
  .Content .entry-image.entry-image-offset img {
    width: calc(100% + 343px);
  }
}
.Content .entry-images {
  left: 0;
  margin: 30px -1rem;
  position: relative;
  width: 100%;
  width: calc(100% + 2rem);
}
.Content .entry-images .entry-image {
  margin-bottom: 30px;
}
.Content .entry-images .entry-image > div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  position: relative;
}
@media (min-width: 500px) {
  .Content .entry-images .entry-image > div {
    height: 300px;
  }
}
@media (min-width: 48rem) {
  .Content .entry-images {
    display: flex;
    flex-direction: row;
    height: 350px;
    margin-bottom: 60px;
    margin-top: 60px;
    position: static;
  }
  .Content .entry-images .entry-image {
    width: 50%;
    margin-bottom: 0;
    position: absolute;
  }
  .Content .entry-images .entry-image:nth-child(1) {
    left: 0;
  }
  .Content .entry-images .entry-image:nth-child(2) {
    right: 0;
  }
  .Content .entry-images .entry-image > div {
    height: 350px;
  }
  .Content .entry-images .entry-image:only-child {
    height: 400px;
  }
  .Content .entry-images .entry-image:only-child > div {
    height: 400px;
  }
}
@media (min-width: 880px) {
  .Content .entry-images {
    height: 350px;
  }
}
@media (min-width: 1080px) {
  .Content .entry-images {
    height: 500px;
  }
  .Content .entry-images .entry-image > div {
    height: 500px;
  }
}
.Content .full-width-image {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
@media (min-width: 62rem) and (max-width: 74.9375rem) {
  .Content .full-width-image {
    margin-left: calc(-50vw - (33.33333% - 2.8125rem));
  }
}

.article-image-banner {
  left: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}
.article-image-banner.article-image-banner-with-caption {
  position: relative;
}
.article-image-banner.article-image-banner-with-caption .image-captions {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.article-image-banner.article-image-banner-with-caption .entry-image-caption {
  width: 50%;
}
.article-image-banner.article-image-banner-with-caption .entry-image-caption:nth-child(2) {
  text-align: right;
}
.article-image-banner div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  flex: 1 0 50%;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
}
.article-image-banner div.sketch {
  background-size: contain;
}
@media (min-width: 500px) {
  .article-image-banner {
    margin-left: -60px;
    margin-right: -60px;
    width: calc(100% + 120px);
  }
}
@media (min-width: 62rem) {
  .article-image-banner {
    display: flex;
    flex-direction: row;
    height: 350px;
    margin-top: 60px;
  }
  .article-image-banner div {
    width: 50%;
    height: 350px;
    margin-bottom: 0;
    position: absolute;
  }
  .article-image-banner div:nth-child(1) {
    left: 0;
  }
  .article-image-banner div:nth-child(2) {
    right: 0;
  }
}
@media (min-width: 1080px) {
  .article-image-banner {
    height: 500px;
  }
  .article-image-banner div {
    height: 500px;
  }
}
