.TeamCarousel .Author {
  display: inline-block;
}
.TeamCarousel .Author > .image {
  text-align: center;
}
.TeamCarousel .Author > .image img {
  height: 200px;
  width: 200px;
}
.TeamCarousel .Author > .meta > .twitter {
  justify-content: center;
}
.TeamCarousel .Author.board-of-trustees {
  margin-top: 2rem;
}
.TeamCarousel .slick-dots {
  padding-left: 2rem;
  padding-right: 2rem;
}
.TeamCarousel .slick-list {
  padding-bottom: 50px !important;
}
.TeamCarousel .slick-slide {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
  text-align: center;
}
