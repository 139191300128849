html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  float: none;
}

.align-baseline {
  align-items: baseline;
}

.title-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

* {
  min-height: 0;
  min-width: 0;
}

@media print {
  @page {
    margin: 1.5cm 2.5cm;
  }
}
