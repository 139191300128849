.AppSearch {
  display: inline-block;
}
.AppSearch > .icon-search {
  font-size: 17px;
  position: relative;
  top: 2px;
}
@media (min-width: 62rem) {
  .AppSearch > .icon-search {
    top: 0;
  }
}
.AppSearch .menu-toggle {
  cursor: pointer;
}
.AppSearch > form {
  margin-right: -15px;
  position: relative;
}
.AppSearch > form > .icon-search {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 17px;
}
.AppSearch > form > input {
  appearance: none;
  background: rgba(43, 43, 43, 0.05);
  border: none;
  padding: 10px 10px 10px 38px;
  height: 32px;
  font-family: "Merriweather", serif;
  font-size: 15px;
  line-height: 1;
  width: 130px;
}
.AppSearch > form > input:focus {
  outline: none;
}
@media (min-width: 36rem) {
  .AppSearch > form > input {
    width: 200px;
  }
}
@media (min-width: 62rem) {
  .AppSearch > form > input {
    width: 378px;
  }
}
