.technology-icon {
  display: inline-block;
  height: 20px;
  line-height: 0;
  margin-right: 10px;
  width: 20px;
}
.technology-icon .injected-svg path {
  fill: var(--fill-color);
}

[class*=-marker-view] .technology-map-marker {
  border-radius: 100%;
}
[class*=-marker-view] .technology-map-marker *:first-child {
  transition: all 250ms ease;
}
[class*=-marker-view] .technology-map-marker:not(.snapshot) {
  transform: scale(1.3);
}
[class*=-marker-view] .technology-map-marker:not(.snapshot) .ring {
  fill: var(--fill-color);
}
[class*=-marker-view] .technology-map-marker:not(.snapshot) .inner-ring,
[class*=-marker-view] .technology-map-marker:not(.snapshot) .outer-ring {
  display: none;
}
[class*=-marker-view] .technology-map-marker:hover .ring,
[class*=-marker-view] .technology-map-marker:hover .outer-ring {
  fill: var(--hover-color);
}
[class*=-marker-view].active .technology-map-marker.snapshot .ring,
[class*=-marker-view].active .technology-map-marker.snapshot .outer-ring {
  fill: var(--active-color);
}
[class*=-marker-view].active .technology-map-marker.snapshot .inner-ring {
  fill: white;
}
[class*=-marker-view].active .technology-map-marker .ring,
[class*=-marker-view].active .technology-map-marker .outer-ring {
  fill: var(--active-color);
}
