.ProductPageFeatureImage img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
@media only screen and (min-width: 62rem) {
  .ProductPageFeatureImage .outset {
    margin-left: -6.125rem !important;
    width: calc(100% + 12.25rem) !important;
  }
}
@media only screen and (min-width: 75rem) {
  .ProductPageFeatureImage .outset {
    margin-left: auto !important;
    width: 100% !important;
  }
}
