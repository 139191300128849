@media only screen {
  .IssueTag {
    color: #2b2b2b;
  }
}
@media only print {
  .IssueTag {
    color: #000000;
  }
}

.IssueTag {
  cursor: pointer;
  display: inline-flex;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  padding: 6px 8px 3px;
  text-transform: uppercase;
  vertical-align: middle;
}
@media only screen {
  .IssueTag {
    background-color: #f7f3ec;
  }
}
@media only print {
  .IssueTag {
    border: 0.2645833333mm solid #000000;
  }
}
@media only screen {
  .IssueTag.active, .IssueTag:hover {
    background-color: #ffa11e;
    color: #ffffff;
  }
  .IssueTag.active .Count, .IssueTag:hover .Count {
    color: #ffffff;
    opacity: 0.6;
  }
}
