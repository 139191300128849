.EventFeatureImage img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
@media (min-width: 62rem) {
  .EventFeatureImage .outset {
    margin-left: -6.125rem !important;
    width: calc(100% + 12.25rem) !important;
  }
}
@media (min-width: 75rem) {
  .EventFeatureImage .outset {
    margin-left: auto !important;
    width: 100% !important;
  }
}
