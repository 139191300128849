@charset "UTF-8";
.SectionLabel {
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
}
.SectionLabel .name {
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.88px;
  margin-right: 0.75em;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .SectionLabel .name {
    margin-right: 1.0625em;
  }
}
.SectionLabel div:not(.name) {
  color: #2b2b2b;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-style: italic;
  opacity: 0.7;
}
.SectionLabel .published-at:after,
.SectionLabel .updated-at:after {
  content: "•";
  margin: 0 0.4375em;
}
.SectionLabel .date {
  color: #2b2b2b;
  font-family: "Merriweather", serif;
  font-style: italic;
}
.SectionLabel .publication-name {
  color: #3a6596;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-right: 0.75em;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .SectionLabel .publication-name {
    margin-right: 1.0625em;
  }
}
