.CategoryList .issue-button {
  padding-bottom: 1rem;
}
@media (min-width: 36rem) {
  .CategoryList .issue-button {
    padding-bottom: 1.5rem;
  }
}
.CategoryList .Button .icon {
  margin-right: 3px;
  vertical-align: baseline;
}
