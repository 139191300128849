.CardStackNavItem {
  cursor: pointer;
  margin-bottom: 12px;
}
.CardStackNavItem > .title {
  font-family: "Merriweather", serif;
  font-size: 13px;
  line-height: 16px;
}
.CardStackNavItem > .title.current {
  font-weight: bold;
}
.CardStackNavItem .image-wrapper {
  padding-top: 66.6%;
  position: relative;
}
.CardStackNavItem .image-wrapper img {
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
}
