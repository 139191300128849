.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.c-pointer {
  cursor: pointer;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 36rem) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 48rem) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 62rem) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 75rem) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

@media (min-width: 36rem) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 48rem) {
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 62rem) {
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
}
@media (min-width: 75rem) {
  .hidden-xl {
    display: none !important;
  }
  .visible-xl {
    display: block !important;
  }
}
@media only print {
  .hidden-print {
    display: none !important;
  }
}
@media only screen {
  .hidden-screen {
    display: none !important;
  }
}
