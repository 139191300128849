@media only screen {
  .EventsPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .EventsPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .EventsPage {
    padding-top: 0 !important;
  }
}

.EventsPage .FeaturedEvent .event-start-date {
  font-family: "Merriweather", serif;
  font-style: italic;
}
