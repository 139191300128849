.SubscribeBox__description {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0.75rem;
}
@media (min-width: 62rem) {
  .SubscribeBox__description {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 0;
    padding-right: 35px;
  }
}
@media (min-width: 75rem) {
  .SubscribeBox__description {
    flex: 1;
  }
}
.SubscribeBox__header {
  display: flex;
  flex-direction: column;
}
@media (min-width: 75rem) {
  .SubscribeBox__header {
    align-items: center;
    flex-direction: row;
  }
}
.SubscribeBox__input {
  border-radius: 0;
  border: 1px solid rgba(43, 43, 43, 0.25);
  color: #2b2b2b;
  display: block;
  font-family: "Merriweather", serif;
  font-size: 13px;
  height: 40px;
  line-height: 1;
  margin-bottom: 1rem;
  outline: none;
  padding: 6px 11px;
  position: relative;
  width: 100%;
}
@media (min-width: 62rem) {
  .SubscribeBox__input {
    font-size: 15px;
    height: 48px;
    padding: 8px 12px 6px;
  }
}
@media (min-width: 75rem) {
  .SubscribeBox__input {
    margin-bottom: 0;
  }
}
.SubscribeBox__input-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}
@media (min-width: 62rem) {
  .SubscribeBox__input-group {
    flex-direction: row;
  }
}
.SubscribeBox__submit-button {
  height: 35px;
  font-size: 13px;
  padding: 12px 20px 8px;
}
@media (min-width: 62rem) {
  .SubscribeBox__submit-button {
    font-size: auto;
    height: 50px;
    margin-left: 20px;
  }
}
.SubscribeBox__title {
  font-size: 18px;
  letter-spacing: 0;
  margin-bottom: 0.75rem;
}
@media (min-width: 62rem) {
  .SubscribeBox__title {
    font-size: 38px;
    margin-bottom: 0;
  }
}
@media (min-width: 75rem) {
  .SubscribeBox__title {
    margin-right: 40px;
  }
}
@media only print {
  .SubscribeBox {
    display: none;
  }
}
@media (min-width: 62rem) {
  .SubscribeBox--small .SubscribeBox__header {
    margin-bottom: 0.5rem;
  }
}
.SubscribeBox--compact {
  margin-bottom: 32px;
}
.SubscribeBox--compact .content {
  padding: 20px 12px;
}
.SubscribeBox--compact .SubscribeBox__header {
  display: block;
}
.SubscribeBox--compact .SubscribeBox__title {
  font-size: 18px;
  margin-bottom: 4px;
}
.SubscribeBox--compact .SubscribeBox__description {
  font-size: 13px;
  line-height: 1.5em;
}
.SubscribeBox--compact .SubscribeBox__button {
  display: block;
  margin: 20px auto 0;
  width: 100%;
}
