.TopicLabel {
  display: inline-flex;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;
}
.TopicLabel .name, .TopicLabel .prefix {
  color: #2b2b2b;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-right: 0.75em;
  opacity: 0.45;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .TopicLabel .name, .TopicLabel .prefix {
    margin-right: 1.0625em;
  }
}
.TopicLabel .bullet,
.TopicLabel .engagement,
.TopicLabel .published-at {
  color: #2b2b2b;
  font-family: "Merriweather", serif;
  font-size: 12px;
  font-style: italic;
  opacity: 0.7;
}
.TopicLabel .bullet {
  margin: 0 0.4375em;
}
.TopicLabel .prefix {
  color: #3a6596;
  margin-right: 0.25em;
  opacity: 1;
}
.TopicLabel .publication-name {
  color: #3a6596;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  margin-right: 0.75em;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .TopicLabel .publication-name {
    margin-right: 1.0625em;
  }
}
