@media only screen {
  .SubscribeModal .mc-field-group > strong {
    color: #2b2b2b;
  }
}
@media only print {
  .SubscribeModal .mc-field-group > strong {
    color: #000000;
  }
}

.SubscribeModal .mc-field-group.input-group ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.SubscribeModal .mc-field-group.input-group ul li {
  background: none;
  margin-bottom: 0;
  padding: 0;
}

.SubscribeModal {
  overflow: scroll;
  /* --- Modal Overrides ---------------------------------------------------- */
  /* --- End Modal Overrides ------------------------------------------------ */
}
.SubscribeModal .modal-container {
  border-top: none;
}
.SubscribeModal .modal-body {
  border-top: 4px solid #ededed;
}
.SubscribeModal .content {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 480px;
}
.SubscribeModal .content .LoadingSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 0;
}
.SubscribeModal p {
  font-size: 13px;
  line-height: 1.54;
}
.SubscribeModal .indicates-required {
  font-size: 13px;
  line-height: 1.54;
  margin-bottom: 1rem;
}
.SubscribeModal input[type=submit] {
  background-color: #3a6596;
  border-radius: 0;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "League Spartan", sans-serif;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 12px 20px 8px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.SubscribeModal input[type=submit]:focus, .SubscribeModal input[type=submit]:hover {
  color: #ffffff;
}
.SubscribeModal .mc-field-group:not(:last-child) {
  margin-bottom: 1rem;
}
.SubscribeModal .mc-field-group > strong {
  font-family: "League Spartan", sans-serif;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.03125rem;
  line-height: 1.18;
}
.SubscribeModal .mc-field-group label {
  display: block;
  font-family: "League Spartan", sans-serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.55;
  text-transform: uppercase;
}
.SubscribeModal .mc-field-group input[type=text],
.SubscribeModal .mc-field-group input.email {
  appearance: none;
  background: #ffffff;
  background-image: none;
  border: 1px solid rgba(43, 43, 43, 0.25);
  border-radius: 0;
  color: #2b2b2b;
  display: block;
  font-family: "Merriweather", serif;
  font-size: 13px;
  line-height: 1;
  max-width: 100%;
  outline: none;
  padding: 8px 10px 6px;
  position: relative;
  width: 100%;
}
.SubscribeModal .mc-field-group input[type=text]:focus,
.SubscribeModal .mc-field-group input.email:focus {
  border-color: #ffa11e;
}
.SubscribeModal .mc-field-group input[type=text]::placeholder,
.SubscribeModal .mc-field-group input.email::placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}
.SubscribeModal .mc-field-group.input-group ul li {
  align-items: center;
  display: flex;
  line-height: 1rem;
  margin: 0.2rem 0;
  min-height: 1.2rem;
  padding: 0.2rem 0.4rem 0.2rem 0;
  position: relative;
}
.SubscribeModal .mc-field-group.input-group ul li input[type=checkbox],
.SubscribeModal .mc-field-group.input-group ul li input[type=radio] {
  cursor: pointer;
  float: left;
  margin-right: 5px;
}
.SubscribeModal .mc-field-group.input-group ul li input[type=checkbox] + label,
.SubscribeModal .mc-field-group.input-group ul li input[type=radio] + label {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.7;
  margin-top: 3px;
  text-transform: none;
  white-space: nowrap;
}
