.map-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 62rem) {
  .map-container .center-map-ui {
    left: 50%;
    position: absolute;
    top: 28px;
    transform: translateX(0);
    width: unset;
  }
}

.mobile-content-container {
  background-color: #F7F1F1;
  padding-top: 12px;
  padding-bottom: 16px;
}
@media (min-width: 62rem) {
  .mobile-content-container {
    background-color: unset;
    padding: 0;
  }
}

.map-caption {
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
  padding: 25px 25px 14px 25px;
}
@media (min-width: 62rem) {
  .map-caption {
    box-sizing: content-box;
    padding-bottom: 70px;
    padding-left: calc(5.625rem + 25px);
    padding-right: calc(5.625rem + 25px);
  }
}
.map-caption .Content p {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: 0.005em;
}
@media (min-width: 62rem) {
  .map-caption .Content {
    max-width: 894px;
  }
}
