.AppLogo .logo {
  background: url("../../images/logotype.png") right center no-repeat;
  background-size: 80px 10px;
  margin-bottom: 0;
  position: relative;
  height: 24px;
  width: 110px;
  text-indent: -9999px;
}
@media (min-width: 62rem) {
  .AppLogo .logo {
    background-size: 133px 16px;
    height: 40px;
    width: 183px;
  }
}
.AppLogo .logo:before {
  content: "";
  background: url("../../images/logomark.png") center center no-repeat;
  background-size: 24px 24px;
  display: block;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}
@media (min-width: 62rem) {
  .AppLogo .logo:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
.AppLogo .logo.inverse {
  background-image: url("../../images/logotype-white.png");
}
.AppLogo .logo.inverse:before {
  background-image: url("../../images/logomark-white.png");
}
.AppLogo .logo.footer {
  background-size: 160px 20px;
  height: 48px;
  width: 220px;
}
.AppLogo .logo.footer:before {
  background-size: 48px 48px;
  height: 48px;
  width: 48px;
}
