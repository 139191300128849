.state-filter.center-map-ui {
  align-items: center;
  column-gap: 8px;
  display: flex;
  margin: 0 25px;
}
@media (min-width: 62rem) {
  .state-filter.center-map-ui {
    align-items: center;
    bottom: unset;
  }
  .map-container .state-filter.center-map-ui {
    left: 5.6%;
  }
}
.state-filter.center-map-ui label {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 1.56;
}
@media (min-width: 62rem) {
  .state-filter.center-map-ui label {
    font-family: "League Spartan", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.001em;
    margin-right: 20px;
    margin-bottom: 0;
    padding-top: 4px;
    text-transform: uppercase;
  }
}
.state-filter.center-map-ui .state-filter-controls {
  column-gap: 8px;
  display: flex;
  flex: 1;
}
.state-filter.center-map-ui .form-select {
  flex: 1;
}
@media (min-width: 62rem) {
  .state-filter.center-map-ui .form-select {
    width: 252px;
  }
}
.state-filter.center-map-ui .clear-filters {
  appearance: none;
  background: none;
  border: none;
  color: #2b2b2b;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  text-wrap: nowrap;
}
@media (min-width: 62rem) {
  .state-filter.center-map-ui .clear-filters {
    margin-left: 20px;
  }
}
