@media only screen {
  .ProductPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .ProductPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .ProductPage {
    padding-top: 0 !important;
  }
}

.ProductPage .featured-image > img {
  height: auto;
  max-width: 100%;
}
.ProductPage .ArticleInfo .type {
  color: #000000;
}
.ProductPage .authors-short {
  display: block;
  font-weight: bold;
}
.ProductPage .Highlight {
  break-inside: avoid;
  margin-bottom: 40px;
}
.ProductPage .Highlight .content {
  padding: 30px 20px;
}
.ProductPage .print-authors {
  height: 0;
  visibility: hidden;
  line-height: 1.5;
  margin: 0;
}
.ProductPage .print-authors .author {
  background: none;
  display: inline;
  font-size: 13px;
  font-style: italic;
  padding: 0;
}
.ProductPage .print-authors .author:not(:nth-last-child(1))::after {
  content: ", ";
  display: inline;
}
.ProductPage .Content .iframe-wrapper iframe {
  width: 100%;
}
@media (min-width: 62rem) {
  .ProductPage .Content .iframe-wrapper {
    margin: 0;
    max-height: none;
    max-width: none;
    width: 100%;
  }
  .ProductPage .Content .iframe-wrapper:not(.contain) {
    margin-left: -343px;
    width: calc(100% + 343px);
  }
}
@media (min-width: 62rem) and (min-width: 75rem) {
  .ProductPage .Content .iframe-wrapper:not(.contain) {
    margin-left: calc(-50% - 12px) !important;
    width: calc(150% + 12px) !important;
  }
}
@media (min-width: 62rem) {
  .ProductPage .Content .iframe-wrapper:not(.tableau) {
    aspect-ratio: var(--ratio);
  }
  .ProductPage .Content .iframe-wrapper:not(.tableau) iframe {
    height: 100%;
  }
}
@media only print {
  .ProductPage .authors-list {
    display: none;
  }
  .ProductPage .print-authors {
    height: auto;
    margin-bottom: 16px;
    visibility: visible;
  }
  .ProductPage .Content {
    font-size: 13px;
  }
  .ProductPage .Content h2 {
    font-size: 26px;
  }
}
