.EntryCard .image {
  position: relative;
}
.EntryCard .image a {
  display: block;
  width: 100%;
}
.EntryCard .image img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.EntryCard .image .publication-logo {
  bottom: 0;
  height: auto;
  max-width: 100px;
  position: absolute;
  right: 0;
  width: 32%;
}
.EntryCard > div {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.EntryCard > div .event-start-date {
  display: block;
  flex: none;
  font-size: 12px;
  font-style: italic;
  height: 18px;
  line-height: 10px;
}
