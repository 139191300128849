.MapPageRelatedMaterials {
  padding: 30px 25px 36px;
}
@media (min-width: 62rem) {
  .MapPageRelatedMaterials {
    padding-bottom: 45px;
    padding-top: 45px;
  }
}
.MapPageRelatedMaterials .heading {
  align-items: center;
  border-bottom: 4px solid #042A3F;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
}
.MapPageRelatedMaterials .heading h2 {
  margin-right: 16px;
}
.MapPageRelatedMaterials .heading .FilterButton {
  margin: 0;
}
@media (min-width: 62rem) {
  .MapPageRelatedMaterials .heading {
    padding-bottom: 20px;
  }
}
