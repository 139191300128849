.Button {
  background-color: #ffa11e;
  border-radius: 0;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "League Spartan", sans-serif;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 12px 20px 8px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.Button:focus, .Button:hover {
  color: #ffffff;
}
.Button.block {
  display: block;
  width: 100%;
}
.Button.primary {
  background-color: #ffa11e;
}
.Button.secondary {
  background-color: #3a6596;
  color: #ffffff;
}
.Button.secondary:focus, .Button.secondary:hover {
  color: #ffffff;
}
.Button.dark {
  background-color: #2b2b2b;
}
.Button.small {
  font-size: 12px;
  padding: 12px 20px 8px;
}
.Button.large {
  font-size: 16px;
  padding: 19px 20px 13px;
}
.Button.xlarge {
  font-size: 20px;
  letter-spacing: 0.83px;
  padding: 30px 20px 28px;
}
.Button:disabled {
  cursor: default;
  opacity: 0.5;
}
