.Pagination {
  margin-top: 42px;
}
@media (min-width: 62rem) {
  .Pagination {
    align-items: center;
    display: flex;
  }
}
.Pagination ul {
  display: flex;
}
.Pagination span {
  background-color: #f7f3ec;
  border: 1px solid #ffffff;
  color: #3a6596;
  display: flex;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  min-width: 41px;
  padding: 14px 15px 10px;
  text-decoration: none;
}
.Pagination span.disabled {
  color: #C5C4C2;
}
.Pagination span.current, .Pagination span:not(.disabled):hover {
  background-color: #ffa11e;
  color: #ffffff;
  cursor: pointer;
}
.Pagination .result-info {
  color: #2b2b2b;
  font-size: 14px;
  margin-top: 20px;
}
@media (min-width: 62rem) {
  .Pagination .result-info {
    margin-top: 0;
  }
  .Pagination .result-info:not(:first-child) {
    margin-left: 20px;
  }
}
.Pagination .next-page,
.Pagination .previous-page {
  padding: 12px 15px 12px;
}
