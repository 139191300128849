@media only screen {
  .LegalPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .LegalPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .LegalPage {
    padding-top: 0 !important;
  }
}

.LegalPage .legal-nav > a {
  color: #2b2b2b;
  display: block;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  line-height: 1.1;
  padding: 12px 0;
  margin-bottom: 10px;
}
@media (min-width: 62rem) {
  .LegalPage .legal-nav > a {
    max-width: 380px;
  }
}
.LegalPage .legal-nav > a.active {
  background: #2b2b2b;
  color: #ffffff;
  padding: 12px 15px;
}
