@media only screen {
  .ContactPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .ContactPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .ContactPage {
    padding-top: 0 !important;
  }
}

.ContactPage .issue-contacts {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ContactPage .issue-contacts li {
  background: none;
  padding-left: 40px;
  position: relative;
}
.ContactPage .issue-contacts li > .icon {
  left: 0;
  font-size: 18px;
  position: absolute;
}
.ContactPage .issue-contacts li h4 {
  text-transform: uppercase;
}
.ContactPage .issue-contacts li .share-links {
  font-size: 13px;
  line-height: 1;
}
.ContactPage .issue-contacts li .share-links a {
  margin-right: 1rem;
}
.ContactPage .map .googleMap {
  display: block;
  height: 320px;
  margin-bottom: 20px;
}
@media (min-width: 62rem) {
  .ContactPage .map {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ContactPage .map .googleMap {
    height: 100%;
    margin-bottom: 0;
    max-height: 550px;
    max-width: 550px;
  }
}
.ContactPage .SocialLinks {
  flex-wrap: wrap;
}
.ContactPage .SocialLinks .SocialLink {
  margin-bottom: 24px;
  margin-left: 0;
  width: 100%;
}
@media (min-width: 62rem) {
  .ContactPage .SocialLinks .SocialLink {
    width: 50%;
  }
}

.SupportThirdWay > h2 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  justify-content: space-between;
}
