.WhoWeAre {
  background-color: #2b2b2b;
  background-image: url("../../images/backgrounds/logomark-lg.png");
  background-position: 0px -90px;
  background-repeat: no-repeat;
  background-size: 806px 808px;
}
.WhoWeAre h1 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 1rem;
}
@media (min-width: 48rem) {
  .WhoWeAre h1 {
    font-size: 38px;
    line-height: 1.5;
    margin-top: 1.5rem;
  }
}
