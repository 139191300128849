.StyleguidePage .color-box {
  color: #ffffff;
  display: flex;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.088rem;
  line-height: 1;
  padding: 20px 40px 16px;
}
.StyleguidePage .typeface-box {
  background: #ffffff;
  border: 4px solid #ededed;
  line-height: 1;
  padding: 28px 30px 24px;
}
