.form-control {
  display: inline-flex;
  width: 100%;
}

.form-group:not(:last-child) {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-family: "League Spartan", sans-serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.55;
  text-transform: uppercase;
}

.form-input {
  appearance: none;
  background: #ffffff;
  background-image: none;
  border: 1px solid rgba(43, 43, 43, 0.25);
  border-radius: 0;
  color: #2b2b2b;
  display: block;
  font-family: "Merriweather", serif;
  font-size: 13px;
  line-height: 1;
  max-width: 100%;
  outline: none;
  padding: 8px 10px 6px;
  position: relative;
  width: 100%;
}
.form-input-lg {
  font-size: 20px;
  padding: 10px 10px 11px;
}
.form-input:focus {
  border-color: #ffa11e;
}
.form-input::placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}

textarea.form-input {
  height: auto;
  line-height: 1.538461538;
}

.form-select {
  appearance: none;
  border: 1px solid rgba(43, 43, 43, 0.25);
  border-radius: 0;
  color: #2b2b2b;
  font-family: "League Spartan", sans-serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.02875rem;
  line-height: 1.5;
  outline: none;
  padding: 10px 10px 8px;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}
.form-select option {
  padding: 0.1rem 0.2rem;
}
.form-select[size], .form-select[multiple] {
  height: auto;
}
.form-select[size] option, .form-select[multiple] option {
  padding: 0.1rem 0.2rem;
}
.form-select:not([multiple]):not([size]) {
  background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA3IDE2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0OC4yICg0NzMyNykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+Q29tYmluZWQgU2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iTW9iaWxlLUFib3V0IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQ1LjAwMDAwMCwgLTE5NjUuMDAwMDAwKSI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTE1LUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQuMDAwMDAwLCAxOTU1LjAwMDAwMCkiIGZpbGw9IiMyQjJCMkIiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMTIiPgogICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTExIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzEuMDAwMDAwLCAxMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMy41LDE2IEwwLDExIEw3LDExIEwzLjUsMTYgWiBNMy41LDAgTDcsNSBMMCw1IEwzLjUsMCBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+") no-repeat right 0.35rem center/0.4375rem 1rem;
  padding-right: 1.2rem;
}
.form-select:focus {
  border-color: #2b2b2b;
  box-shadow: 0 0 0 0.1rem rgba(243, 243, 243, 0.2);
}
.form-select::-ms-expand {
  display: none;
}

.form-checkbox,
.form-radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1rem;
  margin: 0.2rem 0;
  min-height: 1.2rem;
  padding: 0.2rem 0.4rem 0.2rem 1.4rem;
  position: relative;
}
.form-checkbox input,
.form-radio input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
.form-checkbox input:focus + .form-icon,
.form-radio input:focus + .form-icon {
  border-color: #ffa11e;
}
.form-checkbox input:checked + .form-icon,
.form-radio input:checked + .form-icon {
  background: #ffffff;
  border-color: rgba(43, 43, 43, 0.75);
}
.form-checkbox .form-icon,
.form-radio .form-icon {
  border: 0.05rem solid rgb(170.5, 170.5, 170.5);
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.form-checkbox.input-sm,
.form-radio.input-sm {
  font-size: 0.8125rem;
  margin: 0;
}
.form-checkbox.input-lg,
.form-radio.input-lg {
  font-size: 1.125rem;
  margin: 0.3rem 0;
}

.form-checkbox .form-icon,
.form-radio .form-icon {
  background: #ffffff;
  height: 1rem;
  left: 0;
  top: 0.2rem;
  width: 1rem;
}

.form-checkbox .form-icon {
  border-radius: 2px;
}
.form-checkbox input:checked + .form-icon::before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAAH+e/2mAAAABGdBTUEAALGPC/xhBQAAApdJREFUOBGdlUtrU1EQx5ubBykRLBLIQgxFQZA8JUhXYgr6EQJWEenClSt1URTBgAhduepS7MK3XyAbK+lOwYDkJl0ILkrERbKogmhqXv7m5JzLuZcI1oHcmfnPfx7n1c7N2ZLJZDbFR08U7hk2S4V9kVwud1MYIflMJpMQogpo1o4K2CntdlthkuCTbDb72bERstYpedyRdKIfdHAtFosthsrlcqTX6w00OKHuNBtmx244u5vViISf9JkXKETmMvqtOPaopVLpcL/f/ya4iMRUZbKHZIcFZPGSfA+/rP1XrVbrorLlI8KWrY7H48dTb/pNp9PxWq22b2M+m7F+0WnbB2pHtZ4VMBjJm6lUatTtdj+pmU0gqOlwitl3BGctL9WGsvJ0kCi+IYqdTCZXHbI7bNEuC7wmoBHa7xobvVav1/uyz9OLBhKPx2ONRmOQz+dXRqPRcyHT/jtbtyC2E4lEzoohQod+tVp1DFEwQxRbLZDq77CXBLAlHA6fbzabWwbzdsMeR4K0d6maN0TR3uV0HOeIHQgSfWTXdfcArgqYSCSSooPijREM/KvPll/nTm0YPuM/4kxu/HdhDj3KRr+n4GlT1GiKP1CFi8XiwnA43OAmXCb4gjdxyZBmaaY8x5Tytry91Lzf0Wh06SMij/3rYDDY00UlvsLN+lEoFE5qsk/Bf0bROqCvKFNuVyqVeSkqCSH9gsVZFCAg60x/WzCaHUM1GUDdPptH0Suc9lMfZhyWd59J7hrfaJI62E8oeMdgRhP7wi+vb4mBlfYdHlOdoIBMf8jHmu08ZDW3Zof0EwkGafCaBpUgrv19Lv0ZpnT/Elewb2KbyCEt47/hZx/SFnt5geV7fwnsnAPZ/DeIc0OOHigJ8h+LOvwc7b1n7wAAAABJRU5ErkJggg==");
  background-position: 2px 2px;
  background-repeat: no-repeat;
  background-size: 11px 10px;
  content: "";
  display: inline-block;
  height: 1rem;
  margin-bottom: 0;
  width: 1rem;
}
.form-checkbox input:indeterminate + .form-icon {
  background: #ffa11e;
  border-color: #ffa11e;
}
.form-checkbox input:indeterminate + .form-icon::before {
  background: #f3f3f3;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 10px;
}

.form-radio .form-icon {
  border-radius: 50%;
}
.form-radio input:checked + .form-icon::before {
  background: #f3f3f3;
  border-radius: 50%;
  content: "";
  height: 4px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
}
