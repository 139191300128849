@media only screen {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6, body {
    color: #2b2b2b;
  }
}
@media only print {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6, body {
    color: #000000;
  }
}

body {
  font-family: "Merriweather", serif;
  font-size: 16px;
  line-height: 1.875;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "League Spartan", sans-serif;
  letter-spacing: 0.03125rem;
  page-break-after: avoid;
}
h1.inverse,
.h1.inverse,
h2.inverse,
.h2.inverse,
h3.inverse,
.h3.inverse,
h4.inverse,
.h4.inverse,
h5.inverse,
.h5.inverse,
h6.inverse,
.h6.inverse {
  color: #ffffff;
}
h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  text-decoration: none;
}
@media only screen {
  h1 a,
  .h1 a,
  h2 a,
  .h2 a,
  h3 a,
  .h3 a,
  h4 a,
  .h4 a,
  h5 a,
  .h5 a,
  h6 a,
  .h6 a {
    color: #2b2b2b;
  }
}
@media only print {
  h1 a,
  .h1 a,
  h2 a,
  .h2 a,
  h3 a,
  .h3 a,
  h4 a,
  .h4 a,
  h5 a,
  .h5 a,
  h6 a,
  .h6 a {
    color: #000000;
  }
}

h1,
.h1 {
  font-size: 28px;
  line-height: 1.357;
  font-weight: bold;
}
@media (min-width: 62rem) {
  h1,
  .h1 {
    font-size: 38px;
    line-height: 1.21;
  }
}

h2,
.h2 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
}
@media (min-width: 62rem) {
  h2,
  .h2 {
    font-size: 28px;
    line-height: 1.357;
  }
}

h3,
.h3 {
  font-size: 17px;
  line-height: 1.18;
  font-weight: bold;
}
@media (min-width: 62rem) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 1.25;
  }
}

h4,
.h4 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
}

h5,
.h5 {
  font-size: 17px;
  line-height: 1.176470588;
  font-weight: bold;
}

h6,
.h6 {
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

p {
  margin-bottom: 1rem;
}
p.inverse {
  color: #ffffff;
}
p.lead {
  font-size: 16px;
  line-height: 1.916666667;
}
@media (min-width: 48rem) {
  p.lead {
    font-size: 20px;
  }
}
p.strong {
  font-weight: bold;
}
p.italics {
  font-style: italic;
}

.text-alpha {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0.0025em;
}
@media (min-width: 36rem) {
  .text-alpha {
    font-size: 40px;
  }
}
@media (min-width: 62rem) {
  .text-alpha {
    font-size: 65px;
  }
}

.text-beta {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0.0025em;
}
@media (min-width: 36rem) {
  .text-beta {
    font-size: 34px;
  }
}
@media (min-width: 62rem) {
  .text-beta {
    font-size: 45px;
  }
}

.text-gamma {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.47;
  letter-spacing: 0;
}
@media (min-width: 62rem) {
  .text-gamma {
    font-size: 24px;
    line-height: 1.65;
  }
}

.text-delta {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.005em;
}
@media (min-width: 62rem) {
  .text-delta {
    font-size: 34px;
    line-height: 1;
  }
}

.text-epsilon {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 0.001em;
}
@media (min-width: 62rem) {
  .text-epsilon {
    font-size: 25px;
    line-height: 1.02;
    letter-spacing: -0.0075em;
  }
}

.text-zeta {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.001em;
}
@media (min-width: 62rem) {
  .text-zeta {
    font-size: 21px;
    line-height: 1.08;
  }
}

.text-eta {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 0.001em;
}
@media (min-width: 62rem) {
  .text-eta {
    font-size: 17px;
    line-height: 1;
  }
}

.text-theta {
  color: #042A3F;
  font-family: "League Spartan", sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: 0.001em;
}

.text-paragraph-large {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: 0;
}
@media (min-width: 62rem) {
  .text-paragraph-large {
    font-size: 21px;
    line-height: 1.38;
  }
}

.text-paragraph {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.65;
  letter-spacing: 0;
}
@media (min-width: 62rem) {
  .text-paragraph {
    font-size: 16px;
    line-height: 1.75;
  }
}

.text-caption {
  color: #042A3F;
  font-family: "Merriweather", serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.56;
  letter-spacing: 0.005em;
}

.inverse p {
  color: white;
}

small {
  display: inline-flex;
  font-size: 13px;
  line-height: 1.538461538;
}

strong {
  font-weight: bold;
}

sup {
  display: inline;
  font-size: smaller;
  line-height: 1;
  vertical-align: super;
}

em {
  font-style: italic;
}

dt {
  color: #a1a1a1;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  text-transform: uppercase;
}
dt a {
  color: #ffa11e;
  text-decoration: none;
}

.label {
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
}

.muted {
  color: rgba(43, 43, 43, 0.5);
}

.brand-sans-serif,
.text-sans {
  font-family: "League Spartan", sans-serif;
}

.brand-serif,
.text-serif {
  font-family: "Merriweather", serif;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-full-width {
  text-transform: full-width;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

@media (min-width: 62rem) {
  .text-lg-paragraph-large {
    font-size: 21px;
    line-height: 1.38;
  }
}
