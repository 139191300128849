#modal-root {
  position: relative;
  z-index: 999;
}

.Modal {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  opacity: 1;
  overflow: hidden;
  padding: 0.5rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 400;
}
.Modal > .modal-container {
  display: block;
  z-index: 1;
}
.Modal > .highlight {
  border-top: 12px solid #ffa11e;
}
.Modal > .modal-overlay {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.Modal .modal-body {
  border-bottom: 4px solid #ededed;
  border-left: 4px solid #ededed;
  border-right: 4px solid #ededed;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}
