.MapPageMethodology {
  max-width: 1234px;
  padding: 40px 25px 30px;
}
@media (min-width: 62rem) {
  .MapPageMethodology {
    padding-bottom: 100px;
    padding-top: 30px;
  }
}
@media (min-width: 62rem) {
  .MapPageMethodology .content-container {
    max-width: 887px;
    padding-right: 30px;
  }
}
.MapPageMethodology .heading h2 {
  color: #000000;
  margin-bottom: 16px;
}
@media (min-width: 62rem) {
  .MapPageMethodology .heading h2 {
    margin-bottom: 12px;
  }
}
.MapPageMethodology .text p:last-of-type {
  margin-bottom: 0;
}
