@media only screen {
  .SeriesPage .container-fluid {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .SeriesPage .container-fluid {
    padding-top: 3.375rem;
  }
}
@media only print {
  .SeriesPage .container-fluid {
    padding-top: 0 !important;
  }
}

.SeriesPage h1 {
  font-size: 48px;
  line-height: 1;
}
.SeriesPage .container-fluid {
  background: rgba(255, 255, 255, 0.95);
}
@media (min-width: 62rem) {
  .SeriesPage .deck {
    column-count: 2;
    column-gap: 2.85rem;
    column-fill: balance;
  }
  .SeriesPage .deck > p {
    margin-bottom: 0;
  }
}
@media (max-width: 48rem) {
  .SeriesPage .series-items {
    padding-top: 2.5rem;
  }
}
.SeriesPage .related-product img {
  height: auto;
  width: 100%;
}
