.TopicCarousel {
  background-color: #f3f3f3;
  overflow: hidden;
  padding: 2.25rem 0;
}
@media (min-width: 62rem) {
  .TopicCarousel {
    background-color: transparent;
    padding: 0;
  }
}
.TopicCarousel .EntryCard {
  margin-bottom: 3.375rem !important;
}
