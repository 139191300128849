.MapPageIntro {
  padding: 34px 25px 50px;
}
@media (min-width: 62rem) {
  .MapPageIntro {
    padding-bottom: 86px;
    padding-top: 108px;
  }
}
.MapPageIntro .heading {
  margin-bottom: 34px;
  max-width: 1173px;
}
@media (min-width: 62rem) {
  .MapPageIntro .heading {
    margin-bottom: 40px;
  }
}
.MapPageIntro .text {
  max-width: 883px;
}
.MapPageIntro .text p:last-of-type {
  margin-bottom: 0;
}
