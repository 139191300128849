.PaginatedEntry .image {
  position: relative;
}
.PaginatedEntry .image img {
  max-width: 100%;
  height: auto;
}
.PaginatedEntry .publication-logo {
  bottom: 0;
  position: absolute;
  right: 0;
  width: 32%;
  height: auto;
}
.PaginatedEntry .event-start-date {
  font-family: "Merriweather", serif;
  font-style: italic;
}
