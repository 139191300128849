.SponsoredMessage {
  background-color: #3a6596;
  color: #ffffff;
  font-size: 16px;
  font-style: italic;
  line-height: 1.875;
  padding: 8px 11px;
}
.SponsoredMessage p a:not(.label):not(.link-secondary) {
  text-shadow: -1px -1px 0 #3a6596, 1px -1px 0 #3a6596, -1px 1px 0 #3a6596, 1px 1px 0 #3a6596;
  background-image: linear-gradient(to top, transparent, transparent 1px, rgba(255, 255, 255, 0.5) 1px, rgba(255, 255, 255, 0.5) 3px, transparent 3px);
  background-repeat: none;
  color: #ffffff;
  position: relative;
  text-decoration: none;
  color: #ffffff;
}
.SponsoredMessage p a:not(.label):not(.link-secondary):hover, .SponsoredMessage p a:not(.label):not(.link-secondary):focus {
  color: rgba(255, 255, 255, 0.85);
}
.SponsoredMessage p:last-child {
  margin-bottom: 0;
}
