.MapPageHero {
  background-color: #042A3F;
  background-position: right 167px;
  background-repeat: no-repeat;
  background-size: auto 379px;
  padding: 45px 25px 40px;
}
@media (min-width: 62rem) {
  .MapPageHero {
    background-position: right 10px;
    background-size: auto 927px;
    padding-bottom: 173px;
    padding-top: 190px;
  }
}
.MapPageHero .heading {
  margin-bottom: 180px;
  max-width: 1091px;
}
@media (min-width: 36rem) {
  .MapPageHero .heading {
    margin-bottom: 240px;
  }
}
@media (min-width: 75rem) {
  .MapPageHero .heading {
    margin-bottom: 40px;
  }
}
.MapPageHero .heading p {
  margin-bottom: 5px;
}
@media (min-width: 62rem) {
  .MapPageHero .heading p {
    margin-bottom: 20px;
  }
}
.MapPageHero .content-container {
  align-content: center;
}
@media (min-width: 48rem) {
  .MapPageHero .content-container .text {
    max-width: 543px;
  }
  .MapPageHero .content-container .text p:last-of-type {
    margin-bottom: 0;
  }
}
.MapPageHero .content-container .images {
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
}
@media (min-width: 62rem) {
  .MapPageHero .content-container .images {
    gap: 26px;
  }
}
.MapPageHero .content-container .images img:first-of-type {
  flex: 1 0 56.6%;
  height: auto;
  max-width: 423px;
  width: 56.6%;
}
@media (min-width: 62rem) {
  .MapPageHero .content-container .images img:first-of-type {
    max-width: none;
  }
}
.MapPageHero .content-container .images img:nth-of-type(2) {
  flex: 1 0 38.5%;
  height: auto;
  max-width: 341px;
  width: 38.5%;
}
@media (min-width: 62rem) {
  .MapPageHero .content-container .images img:nth-of-type(2) {
    max-width: none;
  }
}
