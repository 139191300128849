.ContentCarousel {
  background-color: #f3f3f3;
  overflow: hidden;
  padding: 2.25rem 0 0;
}
@media (min-width: 62rem) {
  .ContentCarousel {
    background-color: transparent;
    padding: 0;
  }
}
