.AboutPageBio .products {
  background-color: #f3f3f3;
}
@media (min-width: 62rem) {
  .AboutPageBio .products {
    background-color: #ffffff;
  }
}
.AboutPageBio .title-bar {
  flex-direction: column;
}
@media (min-width: 48rem) {
  .AboutPageBio .title-bar {
    flex-direction: row;
  }
}
.AboutPageBio img {
  height: auto;
}
.AboutPageBio .bio-nav-link {
  display: inline-block;
  opacity: 0.25;
}
.AboutPageBio .bio-nav-link:not(:first-child) {
  margin-left: 35px;
}
.AboutPageBio .bio-nav-link:hover, .AboutPageBio .bio-nav-link.active {
  opacity: 1;
}
.AboutPageBio .bio-image {
  position: relative;
}
.AboutPageBio .bio-image img {
  display: block;
}
.AboutPageBio .bio-image .download-bio-image {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #ffffff;
  display: none;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  left: 0;
  line-height: 18px;
  padding: 2px 5px;
  position: absolute;
}
.AboutPageBio .bio-image:hover .download-bio-image {
  display: inline-block;
}
