.MapPageProjectSnapshots {
  padding: 30px 25px 36px;
}
@media (min-width: 62rem) {
  .MapPageProjectSnapshots {
    padding-bottom: 90px;
    padding-top: 90px;
  }
}
.MapPageProjectSnapshots .heading {
  border-top: 4px solid #042A3F;
}
@media (min-width: 75rem) {
  .MapPageProjectSnapshots .snapshot-wrapper:last-of-type .snapshot {
    border-right: none;
  }
}
.MapPageProjectSnapshots .snapshot {
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  height: 100%;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
@media (min-width: 75rem) {
  .MapPageProjectSnapshots .snapshot {
    border-bottom: none;
    border-right: 1px solid #E4E4E4;
  }
}
.MapPageProjectSnapshots .snapshot .technology-icon {
  margin-bottom: 16px;
}
.MapPageProjectSnapshots .snapshot .svg-container {
  display: inline-block;
}
