.Input {
  display: inline-flex;
  width: 100%;
}
.Input input,
.Input button,
.Input select,
.Input optgroup,
.Input textarea {
  font-family: "Merriweather", serif;
  font-size: 13px;
  line-height: 1.538461538;
  margin: 0;
  width: 100%;
}
.Input input[type=text] {
  padding: 8px 10px 6px;
  line-height: 1;
}
.Input ::-webkit-input-placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}
.Input ::-moz-placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}
.Input :-ms-input-placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}
.Input :-moz-placeholder {
  color: #2b2b2b;
  opacity: 0.5;
}
