@media only print {
  .ProductDownloads {
    display: none;
  }
}
.ProductDownloads .download-label {
  color: rgba(43, 43, 43, 0.45);
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}
.ProductDownloads ul.download-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "League Spartan", sans-serif;
}
.ProductDownloads ul.download-list li {
  background: none;
  font-weight: bold;
  padding: 4px 0 0 25px;
  position: relative;
  line-height: 1.4em;
  list-style: none;
  margin: 0 0 10px 0;
}
.ProductDownloads ul.download-list li .icon {
  font-size: 20px;
  left: 0;
  position: absolute;
  top: 3px;
}
.ProductDownloads ul.download-list li a {
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: inset 0 -1px #ffffff, inset 0 -3px #ffa11e;
}
.ProductDownloads ul.download-list li a:focus, .ProductDownloads ul.download-list li a:hover {
  color: #ffa11e;
}
