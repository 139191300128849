.IssueFilters .toggle-show-more {
  color: #3a6596;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-family: "League Spartan", sans-serif;
  font-weight: bold;
}
.IssueFilters .toggle-show-more:hover {
  text-decoration: underline;
}
