.OpportunitiesNav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.OpportunitiesNav > a {
  color: #ffa11e;
  display: flex;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  order: 0;
}
@media (min-width: 62rem) {
  .OpportunitiesNav > a {
    color: #2b2b2b;
    font-size: 23px;
  }
}
@media (min-width: 75rem) {
  .OpportunitiesNav > a {
    color: #2b2b2b;
    font-size: 30px;
  }
}
.OpportunitiesNav > a:not(.active) {
  cursor: pointer;
}
@media (min-width: 62rem) {
  .OpportunitiesNav > a:not(.active) {
    opacity: 0.25;
  }
}
.OpportunitiesNav > a:focus, .OpportunitiesNav > a:hover {
  opacity: 1;
}
.OpportunitiesNav > a:not(:last-child) {
  margin-right: 1.5625rem;
}
@media (min-width: 62rem) {
  .OpportunitiesNav > a:not(:last-child) {
    margin-right: 2rem;
  }
}
.OpportunitiesNav > a.active {
  color: #2b2b2b;
  font-size: 28px;
  order: -1;
  white-space: nowrap;
  width: 100%;
}
@media (min-width: 62rem) {
  .OpportunitiesNav > a.active {
    font-size: 23px;
    order: 0 !important;
    width: auto;
  }
}
@media (min-width: 75rem) {
  .OpportunitiesNav > a.active {
    font-size: 30px;
  }
}
.OpportunitiesNav > a.active::after {
  content: "";
  width: 100%;
}
