@media only screen {
  .Author > .meta > .title a, .Author > .meta > .title {
    color: #2b2b2b;
  }
}
@media only print {
  .Author > .meta > .title a, .Author > .meta > .title {
    color: #000000;
  }
}

.Author {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Author .image > img {
  display: inline-block;
  flex: none;
  height: 162px;
  width: 162px;
}
.Author.small, .Author.medium {
  align-items: flex-start;
  flex-direction: row;
  text-align: inherit;
}
.Author.small .image, .Author.medium .image {
  margin-right: 12px;
}
.Author.small .image > img {
  height: 42px;
  width: 42px;
}
.Author.medium .image > img {
  height: 60px;
  width: 60px;
}
.Author > .meta {
  flex: 1;
}
.Author > .meta > .title {
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 2px;
}
.Author > .meta > .position {
  font-family: "Merriweather", serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.230769231;
}
.Author > .meta > .twitter {
  display: flex;
  font-family: "Merriweather", serif;
  font-weight: bold;
}
.Author > .meta > .twitter a {
  align-items: center;
  color: #2ba9e0;
  display: flex;
  font-size: 13px;
}
.Author > .meta > .twitter a:hover i {
  text-decoration: none;
}
.Author.board-of-trustees {
  margin-bottom: 1.5rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  text-align: left;
}
.Author.board-of-trustees > .meta > .position {
  font-size: 16px;
  line-height: 1.5;
}
.Author.board-of-trustees > .meta > .title {
  font-size: 24px;
  line-height: 1.5;
}
