.SecondaryNav ul {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  column-gap: 16px;
  list-style: none;
}
.SecondaryNav li > a {
  color: #ffffff;
  display: block;
  letter-spacing: 0.03125rem;
  text-decoration: none;
  white-space: nowrap;
}
.SecondaryNav li > a:hover {
  color: #ffa11e;
}
@media (min-width: 62rem) {
  .SecondaryNav .donate {
    order: 9999;
  }
}
.SecondaryNav .donate .Button {
  margin-top: -5px;
  padding: 7px 7px 3px;
}
