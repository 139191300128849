.embedded-pdf {
  height: 320px;
}
@media (min-width: 62rem) {
  .embedded-pdf {
    height: 760px;
    margin-left: -343px;
    width: calc(100% + 343px);
  }
}
@media (min-width: 75rem) {
  .embedded-pdf {
    margin-left: calc(-50% - 12px) !important;
    width: calc(150% + 12px) !important;
  }
}
