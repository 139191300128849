.Highlight {
  background-color: #ffffff;
  border-top: 12px solid #ffa11e;
  display: flex;
  flex-direction: column;
}
.Highlight > picture {
  max-width: 100%;
  flex: 0 0 auto;
}
.Highlight > picture img {
  max-width: 100%;
  height: auto !important;
  display: block;
}
.Highlight > .content {
  border-bottom: 4px solid #ededed;
  border-left: 4px solid #ededed;
  border-right: 4px solid #ededed;
  padding: 33px 32px 40px;
}
