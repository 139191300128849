.detail {
  margin-bottom: 4px;
}
.detail dt, .detail dd {
  display: inline-block;
  vertical-align: text-bottom;
}
.detail dt {
  color: #767676;
  font-size: 13px;
  line-height: 1.2em;
  margin-right: 6px;
}
.detail dd {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-style: italic;
  line-height: 1.4em;
}
.detail dd a, .detail dd button {
  appearance: none;
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-style: italic;
  line-height: 1.4em;
  padding: 0;
  text-decoration: underline;
}
.detail.snapshot button {
  text-align: left;
  text-decoration-color: #ffa11e;
  text-decoration-thickness: 2px;
}
