.NotFoundPage .not-found {
  background-color: #2b2b2b;
  background-image: url("../../images/backgrounds/logomark-lg.png");
  background-position: 0px -90px;
  background-repeat: no-repeat;
  background-size: 806px 808px;
}
.NotFoundPage .not-found h1, .NotFoundPage .not-found h2, .NotFoundPage .not-found h3, .NotFoundPage .not-found p {
  color: #ffffff;
  text-align: center;
}
.NotFoundPage .not-found h1 {
  font-size: 90px;
  line-height: 110px;
}
.NotFoundPage .not-found h2 {
  font-size: 32px;
  line-height: 58px;
}
.NotFoundPage .not-found h3 {
  font-size: 24px;
  line-height: 32px;
}
.NotFoundPage .not-found p {
  font-size: 16px;
  line-height: 38px;
}
@media (min-width: 48rem) {
  .NotFoundPage .not-found h1, .NotFoundPage .not-found h2, .NotFoundPage .not-found h3, .NotFoundPage .not-found p {
    text-align: left;
  }
  .NotFoundPage .not-found h1 {
    font-size: 180px;
    line-height: 220px;
  }
  .NotFoundPage .not-found h2 {
    font-size: 48px;
    line-height: 72px;
  }
  .NotFoundPage .not-found h3 {
    font-size: 28px;
    line-height: 38px;
  }
  .NotFoundPage .not-found p {
    font-size: 20px;
    line-height: 46px;
  }
}
