.SeriesPageFeaturedImage {
  align-items: center;
  aspect-ratio: 5/2;
  display: flex;
  justify-content: center;
  min-height: 200px;
  overflow: hidden;
  width: 100%;
}
.SeriesPageFeaturedImage img {
  height: auto;
  min-height: 200px;
  min-width: 100%;
  object-fit: cover;
}
