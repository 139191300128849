.EventContact h3 {
  font-size: 24px;
}
.EventContact ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.EventContact li {
  background: none;
  padding: 0;
  list-style: none;
  margin: 0;
}
.EventContact li.strong {
  font-weight: bold;
}
