.OpportunitiesHeroImage {
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: auto;
  width: 100%;
  height: 400px;
  z-index: 1;
}
@media (min-width: 62rem) {
  .OpportunitiesHeroImage {
    height: 800px;
  }
}
