.text-black {
  color: #000000;
}

.bg-black {
  background-color: #000000;
}

.text-brand-light {
  color: #f7f3ec;
}

.bg-brand-light {
  background-color: #f7f3ec;
}

.text-brand-primary {
  color: #ffa11e;
}

.bg-brand-primary {
  background-color: #ffa11e;
}

.text-brand-secondary {
  color: #3a6596;
}

.bg-brand-secondary {
  background-color: #3a6596;
}

.text-dark-grey {
  color: #2b2b2b;
}

.bg-dark-grey {
  background-color: #2b2b2b;
}

.text-green {
  color: #4fad98;
}

.bg-green {
  background-color: #4fad98;
}

.text-light-grey {
  color: #f3f3f3;
}

.bg-light-grey {
  background-color: #f3f3f3;
}

.text-link {
  color: #2b2b2b;
}

.bg-link {
  background-color: #2b2b2b;
}

.text-white {
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.text-twitter-blue {
  color: #2ba9e0;
}

.bg-twitter-blue {
  background-color: #2ba9e0;
}

.text-facebook-blue {
  color: #316195;
}

.bg-facebook-blue {
  background-color: #316195;
}

.text-linkedin-blue {
  color: #1175b1;
}

.bg-linkedin-blue {
  background-color: #1175b1;
}

.text-youtube-red {
  color: #d0021b;
}

.bg-youtube-red {
  background-color: #d0021b;
}
