.list-inline, .list-unstyled {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.list-inline li, .list-unstyled li {
  background: none;
  margin-bottom: 0;
  padding: 0;
}

ul,
ol {
  margin: 1.5rem 0 1.5rem 2.625rem;
  padding: 0;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin: 1.5rem 0 1.5rem 2.625rem;
}
ul li,
ol li {
  margin-bottom: 1.25rem;
  padding-left: 2px;
}
ul li:last-child,
ol li:last-child {
  margin-bottom: 0;
}
@media only print {
  ul li,
  ol li {
    page-break-inside: avoid;
  }
}

ul {
  list-style-position: outside;
}
ul li {
  background: url("../images/bullet.png") no-repeat 0px 10px transparent;
  background-size: 6px 6px;
  list-style-type: none;
  padding: 0px 0px 1px 20px;
  vertical-align: middle;
}
@media only print {
  ul li {
    background: transparent;
    list-style-type: disc;
    padding: 0px 0px 1px 10px;
  }
}

ol {
  list-style: decimal outside;
}
ol li {
  margin-left: 18px;
  padding-left: 4px;
}
ol ol {
  list-style-type: lower-alpha;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline .list-inline-item {
  display: inline-block;
}
.list-inline .list-inline-item:not(:last-child) {
  margin-right: 2rem;
}
