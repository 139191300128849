@media only screen {
  .AppHeader {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.91);
    position: relative;
    z-index: 400;
    border-top: 6px solid #ffa11e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  }
}
@media only screen {
  .AppHeader .header-container {
    align-items: center;
    display: flex;
    height: 42px;
    margin: 0 auto;
    max-width: 1440px;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 62rem) {
  .AppHeader .header-container {
    height: 72px;
  }
}
.AppHeader .header-container .AppLogo {
  margin-left: 14px;
}
@media only screen and (min-width: 62rem) {
  .AppHeader .header-container .AppLogo {
    margin-left: 48px;
  }
}
@media only print {
  .AppHeader .header-container .AppLogo {
    margin-bottom: 1rem;
    margin-left: 0;
  }
}
