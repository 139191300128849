.AuthorList {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.AuthorList li {
  background: none;
  margin-bottom: 0.5rem;
  padding: 0;
}
.AuthorList.simple li {
  display: inline;
  font-size: 13px;
  font-style: italic;
}
.AuthorList.simple li:not(:nth-last-child(1))::after {
  content: ", ";
  display: inline;
}
