.IssuesNav {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}
.IssuesNav a {
  background-image: none;
  background-position: 0% 0%;
  background-repeat: inherit;
  background-size: auto auto;
  text-decoration: none;
}
.IssuesNav a:focus, .IssuesNav a:hover {
  background-image: none !important;
  color: inherit;
}
.IssuesNav > ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 62rem) {
  .IssuesNav > ul {
    flex-direction: row;
  }
}
.IssuesNav > ul > li {
  display: flex;
  flex-direction: column;
}
.IssuesNav.fixed {
  background-color: #2b2b2b;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 1;
  width: 100%;
}
@media (min-width: 62rem) {
  .IssuesNav.fixed {
    padding: 48px 40px 40px 48px;
    top: 72px;
  }
}
.IssuesNav .SecondaryNav {
  line-height: 2.6;
  padding: 14px 14px 13px;
}
