.Tabs > .controls {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.625rem;
  white-space: nowrap;
}
.Tabs > .controls .Tab:not(:last-child) {
  margin-right: 2.75rem;
}
.Tabs > .controls .Tab:not(.active) {
  cursor: pointer;
  opacity: 0.25;
}
.Tabs > .controls .Tab:focus, .Tabs > .controls .Tab:hover {
  opacity: 1;
}
