.CardStack {
  margin: 35px 0;
  overflow: hidden;
}
.CardStack .card-stack-content {
  min-height: 300px;
  position: relative;
}
@media (min-width: 36rem) {
  .CardStack .card-stack-content {
    padding-left: 30%;
  }
}
.CardStack .form-select {
  margin-bottom: 20px;
}
.CardStack nav {
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 20px;
  width: 30%;
  line-height: 1;
}
.CardStack nav ul {
  padding: 0;
}
.CardStack nav li {
  margin-bottom: 20px;
}
