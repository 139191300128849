.SideNavList > ul {
  display: flex;
  flex-direction: column;
}
.SideNavList > ul > li {
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
  font-family: "League Spartan", sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.SideNavList > ul > li a {
  transition: all 0.1s ease-in;
  color: #2b2b2b;
  display: flex;
  line-height: 1.5;
  margin-bottom: 6px;
  padding: 11px 0 7px;
}
.SideNavList > ul > li a.active, .SideNavList > ul > li a:hover, .SideNavList > ul > li a:focus {
  background-color: #2b2b2b;
  color: #ffffff;
  padding-left: 14px;
  padding-right: 14px;
}
