@media only screen {
  .outset {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
  .inset {
    margin-left: auto !important;
    width: auto !important;
  }
}
@media only screen and (min-width: 36rem) {
  .outset-sm {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
  .inset-sm {
    margin-left: auto !important;
    width: auto !important;
  }
}
@media only screen and (min-width: 48rem) {
  .outset-md {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
  .inset-md {
    margin-left: auto !important;
    width: auto !important;
  }
}
@media only screen and (min-width: 62rem) {
  .outset-lg {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
  .inset-lg {
    margin-left: auto !important;
    width: auto !important;
  }
}
@media only screen and (min-width: 75rem) {
  .outset-xl {
    margin-left: -1rem !important;
    width: calc(100% + 2rem) !important;
  }
  .inset-xl {
    margin-left: auto !important;
    width: auto !important;
  }
}
