.TeamTabs .team-select {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.TeamTabs .team-select label {
  color: rgba(43, 43, 43, 0.45);
  display: none;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  margin-right: 14px;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .TeamTabs .team-select label {
    display: block;
  }
  .TeamTabs .team-select select {
    max-width: 374px;
  }
}
