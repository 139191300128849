.SocialSharing {
  border: 4px solid #ededed;
  padding: 20px;
}
@media only print {
  .SocialSharing {
    display: none;
  }
}
.SocialSharing > ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.SocialSharing > ul > li {
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.SocialSharing > ul > li:not(:last-child) {
  margin-right: 12px;
}
.SocialSharing > ul > li > * {
  cursor: pointer;
}
.SocialSharing.pull-right {
  border-right: none;
  position: relative;
}
.SocialSharing.pull-right:before, .SocialSharing.pull-right:after {
  background: #ededed;
  content: "";
  display: block;
  height: 4px;
  width: 200rem;
  position: absolute;
  left: 0;
}
.SocialSharing.pull-right:before {
  top: -4px;
}
.SocialSharing.pull-right:after {
  bottom: -4px;
}
