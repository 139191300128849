@media only screen {
  .PressPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .PressPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .PressPage {
    padding-top: 0 !important;
  }
}

.PressPage .PressReleaseCard {
  margin-bottom: 42px;
}
.PressPage .press-nav-link {
  display: inline-block;
  opacity: 0.25;
}
.PressPage .press-nav-link:not(:first-child) {
  margin-left: 35px;
}
.PressPage .press-nav-link:hover, .PressPage .press-nav-link.active {
  opacity: 1;
}
