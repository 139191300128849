.TeamGrid .category-select {
  align-items: baseline;
  color: rgba(43, 43, 43, 0.55);
  display: flex;
  flex-direction: row;
  margin-bottom: 34px;
}
.TeamGrid .category-select > .label {
  margin-right: 11px;
}
.TeamGrid .category-select > .menu {
  min-width: 374px;
  width: auto;
}
@media (min-width: 62rem) {
  .TeamGrid .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
@media (min-width: 62rem) {
  .TeamGrid .Author {
    display: inline-block;
    width: 25%;
  }
}
@media (min-width: 75rem) {
  .TeamGrid .Author {
    display: inline-block;
    width: 20%;
  }
}
.TeamGrid .Author > .image {
  margin-right: 0;
}
.TeamGrid .Author > .meta {
  line-height: 1rem;
}
.TeamGrid .Author > .meta > .name {
  margin-top: 0;
}
.TeamGrid .Author > .meta > .position {
  margin-bottom: 3px;
}
.TeamGrid .Author > .meta > .title {
  margin-top: 0;
}
.TeamGrid .Author > .meta > .twitter {
  justify-content: center;
}
