.IssueMenu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}
@media (min-width: 62rem) {
  .IssueMenu {
    border-bottom: none;
    margin-right: 1rem;
  }
}
.IssueMenu .issue-label {
  color: #ffa11e;
  display: flex;
  flex-direction: row;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding: 13px 12px 9px;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .IssueMenu .issue-label {
    padding: 0;
    margin-bottom: 10px;
  }
}
.IssueMenu .issue-label .toggle {
  align-items: center;
  color: #ffa11e;
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
}
@media (min-width: 62rem) {
  .IssueMenu .issue-label .toggle {
    display: none;
  }
}
.IssueMenu .issue-label .toggle .icon-caret {
  display: inline-flex;
  font-size: 16px;
  transition-property: transform;
  transition-duration: 0.25s;
}
.IssueMenu .issue-label .toggle .icon-caret.active {
  transform: rotate(-90deg);
}
.IssueMenu .issue-list {
  font-family: "Merriweather", serif;
  font-size: 14px;
  margin-bottom: 3px;
  margin-top: 4px;
  padding: 0 12px 10px;
}
@media (min-width: 62rem) {
  .IssueMenu .issue-list {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }
}
.IssueMenu .issue-list.collapsed {
  display: none;
}
@media (min-width: 62rem) {
  .IssueMenu .issue-list.collapsed {
    display: block;
  }
}
.IssueMenu .issue-list li {
  line-height: 1.5;
}
.IssueMenu .issue-list li:not(:last-child) {
  margin-bottom: 7px;
}
.IssueMenu .issue-list a {
  color: #ffffff;
}
.IssueMenu .issue-list a:focus, .IssueMenu .issue-list a:hover, .IssueMenu .issue-list a.active {
  color: #ffa11e;
}
.IssueMenu .issue-title {
  color: #ffa11e;
  display: flex;
  white-space: nowrap;
}
.IssueMenu .issue-title .icon {
  margin-right: 5px;
  min-width: 1rem;
}
