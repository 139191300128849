.JumpToSection {
  border: 4px solid #ededed;
  margin-bottom: 1.5rem;
  padding: 16px 15px 12px;
  position: relative;
}
@media only print {
  .JumpToSection {
    display: none;
  }
}
.JumpToSection > .section-toggle {
  display: flex;
  flex-direction: row;
}
.JumpToSection > .section-toggle > .icon-bookmark,
.JumpToSection > .section-toggle > .icon-times,
.JumpToSection > .section-toggle > .label {
  color: #3a6596;
}
.JumpToSection > .section-toggle > .icon-bookmark {
  font-size: 14px;
  margin-right: 12px;
}
.JumpToSection > .section-toggle > .icon-times {
  font-size: 15px;
}
.JumpToSection > .section-toggle > .label {
  flex-grow: 1;
  text-transform: uppercase;
}
.JumpToSection > .section-list > ul {
  display: none;
  margin-top: 14px;
}
.JumpToSection > .section-list > ul li {
  color: #2b2b2b;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 14px;
}
.JumpToSection > .section-list > ul li a {
  color: #2b2b2b;
}
.JumpToSection > .section-list > ul li a:hover, .JumpToSection > .section-list > ul li a:focus {
  color: #ffa11e;
}
.JumpToSection.expanded {
  z-index: 1;
}
.JumpToSection.expanded > .section-list > ul {
  display: block;
}
.JumpToSection.header {
  border: none;
  margin: 0;
  padding: 0;
  position: static;
}
@media (min-width: 48rem) {
  .JumpToSection.header {
    position: relative;
  }
}
.JumpToSection.header.expanded > .section-list {
  background: #ffffff;
  border: 4px solid #ededed;
  margin: auto;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  top: 42px;
}
@media (min-width: 48rem) {
  .JumpToSection.header.expanded > .section-list {
    left: unset;
    right: 0;
    top: 20px;
    width: 300px;
  }
}
.JumpToSection.header.expanded > .section-list > ul {
  max-height: 300px;
  overflow-y: scroll;
  padding: 12px 30px 12px 15px;
}
.JumpToSection.header.expanded > .section-list > ul li {
  display: block;
  margin-right: 0;
  text-transform: none;
  white-space: pre-line;
}
.JumpToSection.header.expanded > .section-list > ul .section-close {
  color: #3a6596;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
}
