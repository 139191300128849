@media only screen {
  .EventPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .EventPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .EventPage {
    padding-top: 0 !important;
  }
}

@media (min-width: 75rem) {
  .EventPage h1 {
    margin-top: 20px;
  }
}
