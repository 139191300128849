.ProjectModal > .modal-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}
.ProjectModal .modal-body {
  background: #ffffff;
  border: none;
  max-width: 920px;
  padding: 30px 15px 40px;
  position: relative;
  width: 95.5vw;
}
@media (min-width: 62rem) {
  .ProjectModal .modal-body {
    padding: 70px 43px 50px;
  }
}
.ProjectModal .close-button {
  background-color: #ffffff;
  border: none;
  position: absolute;
  right: 10px;
  top: 15px;
}
@media (min-width: 62rem) {
  .ProjectModal .content {
    display: flex;
  }
}
@media (min-width: 62rem) {
  .ProjectModal .project {
    width: 260px;
  }
}
.ProjectModal .project-heading {
  align-items: center;
  display: flex;
  margin-bottom: 26px;
}
.ProjectModal .project-title {
  flex: 1;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  padding-right: 45px;
}
@media (min-width: 62rem) {
  .ProjectModal .project-title {
    padding-right: 0;
  }
}
.ProjectModal .snapshot {
  border-top: 1px solid #767676;
  margin-top: 26px;
  padding-top: 22px;
}
@media (min-width: 62rem) {
  .ProjectModal .snapshot {
    border-top: none;
    border-left: 1px solid #d5d5d5;
    flex: 1;
    margin-left: 49px;
    margin-top: 0;
    padding-top: 0;
    padding-left: 49px;
  }
}
.ProjectModal .snapshot-label {
  color: #767676;
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.ProjectModal .snapshot-title {
  max-width: 405px;
}
.ProjectModal .location {
  margin-bottom: 20px;
  margin-top: 10px;
}
.ProjectModal .snapshot-content {
  max-height: 300px;
  overflow: auto;
  padding-right: 20px;
}
.ProjectModal .snapshot-content p:last-of-type {
  margin-bottom: 0;
}
