@media only screen {
  .IssuePage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .IssuePage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .IssuePage {
    padding-top: 0 !important;
  }
}

.IssuePage h1 {
  font-size: 48px;
  line-height: 1;
}
.IssuePage .titlebar {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48rem) {
  .IssuePage .titlebar {
    flex-direction: row;
  }
}
.IssuePage .titlebar h1 {
  margin-bottom: 1rem;
}
@media (min-width: 48rem) {
  .IssuePage .titlebar h1 {
    margin-bottom: 0;
  }
}
.IssuePage .share-links {
  bottom: 0;
}
.IssuePage .share-links > a {
  display: block;
}
.IssuePage .twitter-link {
  color: #2ba9e0;
}
@media (min-width: 62rem) {
  .IssuePage .deck {
    column-count: 2;
    column-gap: 2.85rem;
    column-fill: balance;
  }
  .IssuePage .deck > p {
    margin-bottom: 0;
  }
}
.IssuePage .feature-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}
.IssuePage .topic-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.IssuePage .topic-tags > .Tag {
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}
