img.responsive {
  display: inline-block;
  width: 100% \9 ;
  max-width: 100%;
  height: auto;
}

@media only print {
  img {
    max-width: 100% !important;
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
}
