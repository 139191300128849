.AppFooter .Legal {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Merriweather", serif;
  font-size: 13px;
  justify-content: center;
  opacity: 0.6;
}
@media (min-width: 62rem) {
  .AppFooter .Legal {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.AppFooter .Legal .copyright {
  display: flex;
  font-family: "League Spartan", sans-serif;
}
@media (min-width: 62rem) {
  .AppFooter .Legal .copyright {
    margin-left: 18px;
    margin-right: 38px;
  }
}
.AppFooter .Legal .links a {
  margin-right: 19px;
  color: #ffffff;
  text-decoration: none;
}
.AppFooter .Legal .links a:hover {
  color: #ffa11e;
}
.AppFooter .Legal .links a:last-child {
  margin-right: 0;
}
