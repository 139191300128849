.AppFixedHeader {
  background-color: rgba(255, 255, 255, 0.91);
  border-top: 6px solid #ffa11e;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  position: fixed;
  top: -54px;
  transition: top 0.25s ease, opacity 0.2s ease;
  opacity: 0;
  z-index: 500;
  width: 100%;
}
@media only print {
  .AppFixedHeader {
    display: none !important;
  }
}
@media (min-width: 62rem) {
  .AppFixedHeader {
    top: -84px;
  }
}
.AppFixedHeader.header-visible {
  top: 0;
  opacity: 1;
}
.AppFixedHeader .header-container {
  align-items: center;
  display: flex;
  height: 42px;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 14px;
  justify-content: space-between;
}
@media (min-width: 62rem) {
  .AppFixedHeader .header-container {
    padding: 0 24px;
    height: 72px;
  }
}
.AppFixedHeader .logo {
  padding-left: 32px;
  position: relative;
}
@media (min-width: 62rem) {
  .AppFixedHeader .logo {
    padding-left: 48px;
  }
}
.AppFixedHeader .logo:before {
  content: "";
  background: url("../../images/logomark.png") center center no-repeat;
  background-size: 24px 24px;
  bottom: 0;
  display: block;
  height: 24px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 24px;
}
@media (min-width: 62rem) {
  .AppFixedHeader .logo:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
.AppFixedHeader .title {
  display: none;
  flex: 1;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media (min-width: 36rem) {
  .AppFixedHeader .title {
    display: block;
  }
}
.AppFixedHeader .navigation {
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
}
.AppFixedHeader .navigation .share {
  cursor: pointer;
}
.AppFixedHeader .navigation .share .SocialSharing {
  opacity: 0;
  position: absolute;
  right: -100%;
  top: 42px;
  transition: right 0.35s, opacity 0.25s ease;
}
.AppFixedHeader .navigation .share.share-open .SocialSharing {
  display: block;
  opacity: 1;
  right: 0;
}
.AppFixedHeader .navigation > li {
  font-size: 12px;
  text-transform: uppercase;
}
.AppFixedHeader .navigation > li:not(:last-child) {
  margin-right: 25px;
}
.AppFixedHeader .navigation > li.main-issue i {
  display: inline-block;
  margin-bottom: 2px;
}
.AppFixedHeader .navigation > li > h4,
.AppFixedHeader .navigation > li .JumpToSection {
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
}
.AppFixedHeader .menu-toggle {
  display: block;
  position: relative;
  width: 14px;
}
.AppFixedHeader .SocialSharing {
  border: none;
}
