.JoinOurTeam {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.JoinOurTeam h2 {
  font-size: 24px;
  margin-bottom: 17px;
}
@media (min-width: 48rem) {
  .JoinOurTeam h2 {
    font-size: 38px;
  }
}
.JoinOurTeam > .content {
  flex-direction: column;
  padding-bottom: 5px;
  padding-top: 24px;
}
@media (min-width: 62rem) {
  .JoinOurTeam > .content {
    flex-direction: row;
  }
}
.JoinOurTeam > .content > .title {
  display: flex;
  width: 100%;
}
@media (min-width: 75rem) {
  .JoinOurTeam > .content > .title {
    display: inline-flex;
    width: 30%;
  }
}
.JoinOurTeam > .content > .buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 48rem) {
  .JoinOurTeam > .content > .buttons {
    flex-direction: row;
    width: 100%;
  }
  .JoinOurTeam > .content > .buttons .Button {
    display: flex;
    flex-grow: 1;
    margin-right: 1.5rem;
    justify-content: center;
  }
  .JoinOurTeam > .content > .buttons .Button.careers {
    width: 16%;
  }
  .JoinOurTeam > .content > .buttons .Button.internships {
    width: 16%;
  }
  .JoinOurTeam > .content > .buttons .Button.fellows-program {
    width: 22%;
  }
}
@media (min-width: 75rem) {
  .JoinOurTeam > .content > .buttons {
    display: inline-flex;
    width: 70%;
  }
  .JoinOurTeam > .content > .buttons .Button:last-child {
    margin-right: 0;
  }
}
.JoinOurTeam .Button {
  margin-bottom: 24px;
}
