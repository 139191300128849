.SideNavListContent .Content blockquote,
.SideNavListContent .Content .pullquote {
  margin-left: 0;
}
.SideNavListContent .Content table {
  margin-left: 0;
  width: auto;
}
.SideNavListContent .Content .entry-image {
  margin-left: 0;
}
.SideNavListContent .Content .entry-image.entry-image-left {
  margin-left: 0;
  width: auto;
}
.SideNavListContent .Content .entry-image.entry-image-right {
  margin-right: 0;
  width: auto;
}
.SideNavListContent .Content .full-width-image {
  margin-left: 0;
}
.SideNavListContent iframe.offset {
  margin-left: 0;
  width: auto;
}
