.SeriesBar {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
}
@media only print {
  .SeriesBar {
    display: none;
  }
}
.SeriesBar > .image {
  display: flex;
  min-width: 25%;
}
@media (min-width: 62rem) {
  .SeriesBar > .image {
    min-width: 13.75%;
  }
}
.SeriesBar > .image img {
  display: block;
}
.SeriesBar > .content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-width: 75%;
  padding-left: 1rem;
}
@media (min-width: 62rem) {
  .SeriesBar > .content {
    min-width: 65%;
  }
}
.SeriesBar > .content > .title {
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 1.53;
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (min-width: 62rem) {
  .SeriesBar > .content > .title {
    font-size: 15px;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
}
.SeriesBar > .content > .title a {
  color: #2b2b2b;
}
.SeriesBar > .content > .title a:focus, .SeriesBar > .content > .title a:hover {
  color: #ffa11e;
}
.SeriesBar > .content > .TopicLabel {
  margin-bottom: 0;
}
@media (min-width: 62rem) {
  .SeriesBar > .content > .TopicLabel {
    margin-bottom: 4px;
  }
}
.SeriesBar > .link {
  display: none;
  flex: 1;
  justify-content: flex-end;
  padding-right: 1rem;
  white-space: nowrap;
}
@media (min-width: 62rem) {
  .SeriesBar > .link {
    display: flex;
    min-width: 16.25%;
  }
}
.SeriesBar > .link a > .icon {
  vertical-align: baseline;
}
.SeriesBar > .link .icon-th-list {
  font-size: 14px;
}
