.Donate .form-elements {
  width: 100%;
}
@media (min-width: 48rem) {
  .Donate .form-elements {
    width: 272px;
  }
}
.Donate .form-group {
  margin-bottom: 36px;
  position: relative;
}
.Donate .form-group:before {
  color: rgba(43, 43, 43, 0.5);
  content: "$";
  font-size: 18px;
  left: 11px;
  position: absolute;
  top: 7px;
  z-index: 1;
}
@media (min-width: 48rem) {
  .Donate .donate-button {
    display: block;
    width: 100%;
  }
}
.Donate .donate-input {
  color: #2b2b2b;
  font-weight: bold;
  padding-left: 28px;
}
