@media only print {
  .AppNav {
    display: none;
  }
}
.AppNav ul {
  align-items: center;
  display: inline-flex;
  list-style: none;
  margin-right: 15px;
}
@media (min-width: 62rem) {
  .AppNav ul {
    margin-right: 15px;
  }
}
.AppNav ul > li {
  display: inline;
  font-family: "League Spartan", sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-left: 25px;
  text-transform: uppercase;
}
@media (min-width: 62rem) {
  .AppNav ul > li {
    margin-left: 32px;
  }
}
.AppNav a {
  color: #2b2b2b;
  letter-spacing: 0.03125rem;
  text-decoration: none;
}
.AppNav .menu-toggle {
  display: block;
  position: relative;
  top: 1px;
  width: 14px;
}
