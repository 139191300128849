.ProductPageBoard__content {
  margin-bottom: 1rem;
}
.ProductPageBoard__image {
  width: 100%;
  height: auto;
}
.ProductPageBoard__links {
  display: flex;
  font-size: 13px;
  margin: 0;
  list-style-type: none;
}
.ProductPageBoard__links li {
  background: none;
  padding: 0 1rem 0 0;
}
.ProductPageBoard__title {
  font-size: 36px;
}
