.EventDetails h3 {
  font-size: 24px;
}
.EventDetails ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.EventDetails li {
  background: none;
  padding: 0;
  list-style: none;
  margin: 0;
}
.EventDetails li.strong {
  font-weight: bold;
}
.EventDetails .googleMap {
  height: 166px;
}
