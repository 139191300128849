.SocialLink {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.SocialLink:hover {
  opacity: 0.8;
}
.SocialLink .brand-icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.SocialLink .brand-icon > a {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.SocialLink .brand-title {
  flex: 2;
  font-family: "League Spartan", sans-serif;
  letter-spacing: 0.25px;
  margin-left: 11px;
}
.SocialLink.large .brand-icon {
  font-size: 22px;
}
.SocialLink.large .brand-icon > a {
  width: 48px;
  height: 48px;
}
.SocialLink.large .brand-title {
  font-size: 14px;
}
.SocialLink.twitter .brand-icon {
  background-color: #2ba9e0;
}
.SocialLink.twitter .brand-title {
  color: #2ba9e0;
}
.SocialLink.facebook .brand-icon {
  background-color: #316195;
}
.SocialLink.facebook .brand-title {
  color: #316195;
}
.SocialLink.instagram .brand-icon {
  background-color: #ed3269;
}
.SocialLink.instagram .brand-title {
  color: #ed3269;
}
.SocialLink.linkedin .brand-icon {
  background-color: #1175b1;
}
.SocialLink.linkedin .brand-text {
  color: #1175b1;
}
.SocialLink.youtube .brand-icon {
  background-color: #d0021b;
}
.SocialLink.youtube .brand-text {
  color: #d0021b;
}
.SocialLink.email .brand-icon {
  background-color: #4fad98;
}
.SocialLink.print .brand-icon {
  background-color: #2b2b2b;
}
