@media only screen {
  .text-anchor,
  p a:not(.label, .link-secondary) {
    color: #2b2b2b;
  }
}
@media only print {
  .text-anchor,
  p a:not(.label, .link-secondary) {
    color: #000000;
  }
}

@media only screen {
  .text-anchor,
  p a:not(.label, .link-secondary) {
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
    background-image: linear-gradient(to top, transparent, transparent 1px, #ffa11e 1px, #ffa11e 3px, transparent 3px);
    background-repeat: none;
    color: #2b2b2b;
    position: relative;
    text-decoration: none;
  }
}
.text-anchor:focus, .text-anchor:hover,
p a:not(.label, .link-secondary):focus,
p a:not(.label, .link-secondary):hover {
  color: #ffa11e;
}

a {
  text-decoration: none;
  color: #3a6596;
}
@media only print {
  a {
    text-decoration: underline;
  }
}
a.label {
  display: inline-block;
  font-family: "League Spartan", sans-serif;
  text-decoration: none;
}
a.label:focus, a.label:hover {
  color: #ffa11e;
}

.link-secondary {
  color: #3a6596;
}

.share-links {
  bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  position: relative;
  row-gap: 5px;
}
.share-links > a {
  align-items: center;
  color: #2b2b2b;
  display: inline-flex;
  text-decoration: none;
  white-space: nowrap;
}
.share-links > a .icon {
  margin-right: 0.25em;
}
.share-links > a.twitter-link {
  color: #2ba9e0;
}
.share-links > a.email-link {
  color: #4fad98;
}
.share-links > a.muted {
  color: rgba(43, 43, 43, 0.5);
}
.share-links > a:hover {
  color: #ffa11e;
}
.share-links > a:not(:last-child) {
  margin-right: 1.5rem;
}

.twitter-handle {
  color: #2ba9e0;
  font-family: "League Spartan", sans-serif;
  font-size: 14px;
}
.twitter-handle:focus, .twitter-handle:hover {
  color: #2ba9e0;
}
.twitter-handle > [data-icon] {
  font-size: 16px;
}
