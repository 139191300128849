@media only screen {
  .SearchPage {
    padding-top: 1.5rem;
  }
}
@media only screen and (min-width: 62rem) {
  .SearchPage {
    padding-top: 3.375rem;
  }
}
@media only print {
  .SearchPage {
    padding-top: 0 !important;
  }
}

.SearchPage .search-nav-link {
  display: inline-block;
  opacity: 0.25;
}
.SearchPage .search-nav-link:not(:last-child) {
  margin-right: 35px;
}
.SearchPage .search-nav-link:hover, .SearchPage .search-nav-link.active {
  opacity: 1;
}
